// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {FirebaseOptions} from '@angular/fire/app';

export const environment = {
  environment: 'Dev',
  production: false,
  storageUrl: 'gs://gallix-qa.appspot.com',
  firebaseConfig: {
    apiKey: 'AIzaSyBvoX7ultn4eUAUVENdbq2EkNXBrMCckN4',
    authDomain: 'gallix-qa.firebaseapp.com',
    projectId: 'gallix-qa',
    storageBucket: 'gallix-qa.appspot.com',
    messagingSenderId: '695323828389',
    appId: '1:695323828389:web:8f9fbdda36afd66088ed4c',
    measurementId: 'G-K4KT67B6EL'
  } as FirebaseOptions
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
