import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import {FireAuthService} from '../../../shared-utilities/services-old/fire-auth.service';
import {MessagesService} from '../../../shared-utilities/services-old/message-service/messages.service';
import {select, Store} from '@ngrx/store';
import {forkJoin, from, Observable, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStores} from '../../../features-as-modules/feature-core/store/core.selectors';
import * as StoreSettingsActions from './settings.actions';
import * as StockManagerActions from '../../stock-manager/store/stock-manager.actions';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {DisabledRules, LineColour, TableNavSettings} from '../../../shared-utilities/models-old/datastructures';

@Injectable()
export class SettingsEffects {

  // ===========================================================================
  // get NGP Report Options
  // ===========================================================================
  getNGPReportOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getNGPReportColPreviewSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([action, selectedStore]) => {
        const colPreviews$: Observable<{
          data: { [p: number]: string };
          error: IError
        }> = from(this.firebaseService.getUserDocument('ngp_edits_preview'))
          .pipe(
            take(1),
            map((data: { [key: number]: string }) => ({data, error: null})),
            catchError((error: IError) => of({data: null, error})),
          );

        return forkJoin({
          colPreview: colPreviews$,
        }).pipe(
          map(({colPreview}) => {
            const errors: IError[] = [];
            if (colPreview.error) {
              errors.push(colPreview.error);
            }
            if (errors.length > 0) {
              return StoreSettingsActions.getNGPReportColPreviewFailure({
                errors,
                store: selectedStore,
              });
            }

            return StoreSettingsActions.getNGPReportColPreviewSuccess({
              store: selectedStore,
              colPreview: colPreview.data,
            });
          }),
          catchError((error: IError) => {
            return of(StoreSettingsActions.getNGPReportColPreviewFailure({
              errors: [error],
              store: selectedStore,
            }));
          }),
        );
      }),
    ),
  );


  // ===========================================================================
  // set NGP Report Preview Columns
  // ===========================================================================
  setNgpReportOptionsPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setNGPReportColPreviewSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      switchMap(([action, userSelectedStore]) => {
        return from(this.firebaseService.setUserSingularDocuments('ngp_edits_preview', action.columnData))
          .pipe(
            map(() => StoreSettingsActions.setNGPReportColPreviewSettingsSuccess({
              columnData: action.columnData,
              store: userSelectedStore,
            })),
            catchError((error: IError) => of(StoreSettingsActions.setNGPReportColPreviewSettingsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );

  // ===========================================================================
  // set NGP Report Table Navigation Settings
  // ===========================================================================
  setTableNavSettingsInFirebase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setTableNavSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([settings, userSelectedStore]) => {
        return from(this.firebaseService.setUserSingularDocuments('ngp_table_nav', settings.settings))
          .pipe(
            map(() => StoreSettingsActions.setTableNavSettingsSuccess({
                settings: settings.settings,
                store: userSelectedStore,
              }),
            ),
            catchError((error: IError) => of(StoreSettingsActions.setTableNavSettingsFailure({
              error,
              store: userSelectedStore,
            }))),
          );
      }),
    ),
  );

  // ===========================================================================
  // get Table Navigation Settings
  // ===========================================================================
  getTableNavSettings$ = createEffect(() => this.actions$.pipe(
    ofType(StoreSettingsActions.getTableNavSettings),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    mergeMap(([_, userSelectedStore]) => {
      return from(this.firebaseService.getUserDocument('ngp_table_nav')).pipe(
        take(1),
        map((data: TableNavSettings) => {
          return StoreSettingsActions.getTableNavSettingsSuccess({
            settings: data,
            store: userSelectedStore,
          });
        }),
        catchError((error: IError) => {
          return of(StoreSettingsActions.getTableNavSettingsFailure({
            error,
            store: userSelectedStore,
          }));
        }),
      );
    }),
  ));

  getDisabledRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getItemDisablingRules),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([action, store]) =>
        this.firebaseService.subStoreDataDoc('stock_item_deletion_rules', store.storeId, 'operational').pipe(
          map((rulesDoc: DisabledRules) => StoreSettingsActions.getItemDisablingRulesSuccess({rulesDoc, store})),
          catchError((error: IError) => of(StoreSettingsActions.getItemDisablingRulesFailure({error, store}))),
        ),
      ),
    ),
  );


  getLineColour$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getLineColour),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([action, store]) =>
        from(this.firebaseService.getStoreDataDoc('line_colours', store.storeId)).pipe(
          map((colour: LineColour) =>
            StoreSettingsActions.getLineColourSuccess({colour, store}),
          ),
          catchError((error: IError) =>
            of(StoreSettingsActions.getLineColourFailure({error, store})),
          ),
        ),
      ),
    ),
  );


  getStockManagerColPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.getStockManagerColPreviewSettings),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([_, store]) =>
        from(this.firebaseService.getUserDocument('stock_edits_preview')).pipe(
          map((colPreview: any) =>
            StoreSettingsActions.getStockManagerColPreviewSettingsSuccess({colPreview, store}),
          ),
          catchError((errors: IError) =>
            of(StoreSettingsActions.getStockManagerColPreviewSettingsFailure({errors})),
          ),
        ),
      ),
    ),
  );

  setStockManagerColPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StoreSettingsActions.setStockManagerPreviewColumns),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      switchMap(([action, userSelectedStore]) =>
        from(this.firebaseService.setUserSingularDocuments('stock_edits_preview', action.columnData)).pipe(
          mergeMap(() => [
            StoreSettingsActions.setStockManagerPreviewColumnsSuccess({
              columnData: action.columnData,
              store: userSelectedStore,
            }),
            StockManagerActions.setStockManagerPreviewColumnsSuccess({
              columnData: action.columnData,
            }),
          ]),
          catchError((error: IError) =>
            of(StoreSettingsActions.setStockManagerPreviewColumnsFailure({
              error,
              store: userSelectedStore,
            })),
          ),
        ),
      ),
    ),
  );


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private fireAuthService: FireAuthService,
    private messagesService: MessagesService,
    private readonly store: Store,
  ) {
  }
}
