import {GridColumnHeaderComponent} from './grid-column-header/grid-column-header.component';
import {SharedGridComponent} from './shared-grid/shared-grid.component';
import {SharedGridTableSettingsComponent} from './shared-grid-table-settings/shared-grid-table-settings.component';

export const GRID_COLUMN_HEADER = GridColumnHeaderComponent;
export const SHARED_GRID = SharedGridComponent;
export const SHARED_GRID_INPUT_CONTAINER = SharedGridTableSettingsComponent;

export const SHARED_GRID_COMPONENTS = [
  GRID_COLUMN_HEADER,
  SHARED_GRID,
  SHARED_GRID_INPUT_CONTAINER,
];
