import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SHARED_COMPONENTS} from './components';
import {SharedModule} from '../../shared-modules/shared-module/shared-module.module';
import {IonicModule} from '@ionic/angular';
import {SharedIconsModule} from '../../shared-modules/shared-icons/shared-icons.module';
import {FormsModule} from '@angular/forms';
import {PushModule} from '@ngrx/component';

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS
  ],
    imports: [
        CommonModule,
        SharedModule,
        IonicModule,
        SharedIconsModule,
        FormsModule,
        PushModule,
        NgOptimizedImage
    ],
  exports: [
    ...SHARED_COMPONENTS
  ]
})
export class SharedComponentsModule {
}
