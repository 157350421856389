import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FieldPath} from 'firebase/firestore';
import * as CryptoJS from 'crypto-js';
import {FireAuthService} from '../fire-auth.service';
import {FirebaseService} from '../firebase.service';
import {AlertController} from '@ionic/angular';
import {Colleague, StoreInfo} from '../../models-old/datastructures';
import {superSafeURLEncode} from '../../utils-old/formatting';
import {IUserAccess} from '../../../shared/shared-models/user-access/user-access';

@Injectable({
  providedIn: 'root'
})
export class ColleaguesService {

  private userAccess: IUserAccess;

  private colleagues: { users: { [userId: string]: Colleague }; storesUsers: { [storeId: string]: string[] } };
  private stores: { stores: { [p: string]: StoreInfo }; order: string[] };
  private trimmedAccess: { [userId: string]: IUserAccess };
  private outStandingLinksBS: BehaviorSubject<{ ts: Date; storeId: string; expired: boolean }[]>
    = new BehaviorSubject(null);

  private infoPacketBS: BehaviorSubject<{
    colleagues: { users: { [userId: string]: Colleague }; storesUsers: { [storeId: string]: string[] } };
    stores: { stores: { [p: string]: StoreInfo }; order: string[] };
    userAccess: { [userId: string]: IUserAccess };
  }> = new BehaviorSubject({
    colleagues: {users: {}, storesUsers: {}}, stores: {stores: {}, order: []}, userAccess: {}
  });
  private storesSub: Subscription;
  private canEdit: { [storeId: string]: boolean } = {};

  constructor(
    private af: AngularFirestore,
    private fbService: FirebaseService,
    private fireAuth: FireAuthService,
    private alertControl: AlertController,
  ) {
    let sentLinksSub: Subscription;
    this.fireAuth.userAccess.subscribe(ua => {
      this.userAccess = ua;

      if (ua) {
        const canEdit = {};
        ua.storeList.forEach((storeId) => {
          if (this.fireAuth.hasAccess(storeId, {ruleID: 'a.'}) === true) {
            canEdit[storeId] = true;
          }
        });
        const accessChanged = (''+(Object.keys(canEdit).sort())) !== (''+(Object.keys(this.canEdit).sort()));
        this.canEdit = canEdit;


        this.setInfoPacket();

        if (accessChanged) {
          if (sentLinksSub) { sentLinksSub.unsubscribe(); }
          sentLinksSub = this.af.collection('/signup_links', (ref) =>
            ref.where('storeId', 'in', Object.keys(this.canEdit))
          ).valueChanges().subscribe((vcs) => {
            const now = new Date();
            this.outStandingLinksBS.next(vcs.map((vc) => {
              const doc = vc as { userId: string; storeId: string; ts: Date; secret: string };
              doc.ts = (doc.ts as any).toDate();
              return { ts: doc.ts, storeId: doc.storeId, expired: (now.getTime() - doc.ts.getTime()) / 3600000 > 12};
            }).sort((a, b) => a.ts.getTime() < b.ts.getTime() ? 1 : -1));
          });
        }
      }
    });
    this.fbService.stores.subscribe((obj) => {
      this.stores = obj;
      this.setInfoPacket();
    });
    this.fbService.colleagues.subscribe((colleagues) => {
      this.colleagues = colleagues;
      this.setInfoPacket();
    });
    // allowedEdit(storeId)
    // this.af.collection('/signup_links', (ref) => {
    //   ref.where('storeId')
    // });
  }

  get packetInfoObs(): Observable<{
    colleagues: {users: { [userId: string]: Colleague }; storesUsers: { [storeId: string]: string[] }};
    stores: { stores: { [p: string]: StoreInfo }; order: string[] };
    userAccess: { [userId: string]: IUserAccess };
  }> {
    return this.infoPacketBS.asObservable();
  }

  get access(): IUserAccess {
    return this.userAccess;
  }

  allowedEdit(storeId: string): boolean {
    return !!this.canEdit[storeId];
  }

  async resetPwd(userId) {
    const storeId = Object.keys(this.colleagues.users[userId].stores).find((sID) =>
      this.fireAuth.hasAccess(sID, {ruleID: 'a.'}) === true);

    if (!storeId) { return; }
    const ac = await this.alertControl.create({header: 'Send Reset Email?', subHeader: 'A password reset email will ' +
        `be sent to ${userId}.`, message: 'The user can follow the instructions, and link, in the email to reset ' +
        'their password.<br><br>Do you want to continue?', cssClass: 'custom-alert',
      buttons: ['No', {text: 'Yes', role: 'y'}]});
    await ac.present();
    const {role} = await ac.onDidDismiss();

    if (role === 'y') {
      await this.fireAuth.sendPasswordResetEmail(userId, storeId);
    }
  }

  private setInfoPacket() {
    if (this.stores && this.stores.order.length) {
      if (this.storesSub) {
        this.storesSub.unsubscribe();
      }
      this.storesSub = this.af.collection('/user_access/', (ref) => ref.where(
        'storeList', 'array-contains-any', this.stores.order
      )).valueChanges({idField: 'userId'}).subscribe((docs) => {
        this.trimmedAccess = {};
        docs.forEach(doc => {
          const userId = doc.userId;
          delete doc.userId;
          const access = doc as any as IUserAccess;
          access.storeList.forEach(storeId => {
            if (!this.stores.stores.hasOwnProperty(storeId) || !this.canEdit[storeId]) {
              if (access.stores && access.stores[storeId]) {
                delete access.stores[storeId];
              }
            }
          });
          delete access.storeList;
          this.trimmedAccess[userId] = access;
        });

        if (this.colleagues && this.stores && this.trimmedAccess) {
          this.infoPacketBS.next({
            colleagues: this.colleagues, stores: this.stores, userAccess: this.trimmedAccess
          });
        }
      });
    }
  }
}
