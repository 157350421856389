import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AlertController, ModalController, ToastController} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {FirebaseError} from '@angular/fire/app';
import {Message} from '../models-old/datastructures';
import {AccessFlags, RuleHumanID, RuleInfo, ruleStructure} from '../models-old/utils-old/rule-structure';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {StandardAlertsService} from './standard-alerts.service';
import {IUserAccess} from '../../shared/shared-models/user-access/user-access';
import {ISharedModalBasic} from '../../shared/shared-models/modals/shared-modal-basic';
import {Icons} from '../../shared-modules/shared-icons/icons';
import {
  SharedModalBasicComponent
} from '../../shared/shared-components/components/shared-modal-basic/shared-modal-basic.component';
import {getUser, getUserAccess, setCurrentPageAndTab} from '../../features-as-modules/feature-core/store/core.actions';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class FireAuthService {

  private authStateSub: Subscription;
  private userAccessSubject: BehaviorSubject<IUserAccess> = new BehaviorSubject<IUserAccess>(null);
  private readonly userIdSubject: BehaviorSubject<string>;
  private readonly preLogoutCallbacks: { [name: string]: { call: () => void | Promise<void>; promise?: boolean } } = {};
  private readonly ruleFlags = new BehaviorSubject<{ [storeId: string]: boolean[] }>({});

  private authTime: Date;

  constructor(
    private fireAuth: AngularFireAuth,
    private af: AngularFirestore,
    private stdAlert: StandardAlertsService,
    private alertControl: AlertController,
    private toastControl: ToastController,
    private router: Router,
    private modalController: ModalController,
    private readonly store: Store,
  ) {
    this.userIdSubject = new BehaviorSubject<string>(null);
    this.subscribe();
  }

  get userIdSub(): Observable<string> {
    return this.userIdSubject.asObservable();
  }

  get userAccess(): Observable<IUserAccess> {
    return this.userAccessSubject.asObservable();
  }

  hasAccess(storeId: string, ruleIdentity: {
    ruleIndex?: number;
    ruleID?: RuleHumanID | AccessFlags
  }): true | RuleInfo {
    const hasIndex = ruleIdentity.hasOwnProperty('ruleIndex');
    const hasID = ruleIdentity.hasOwnProperty('ruleID');
    let ruleIndex: number = null;

    if (hasID) {
      if (AccessFlags[ruleIdentity.ruleID]) {
        if (this.userAccessSubject.value[ruleIdentity.ruleID]) {
          return true;
        } else if (this.userAccessSubject.value.hasOwnProperty(ruleIdentity.ruleID)) {
          console.warn(`User should not have an empty access flag ${AccessFlags[ruleIdentity.ruleID]}`);
        }
        return AccessFlags[ruleIdentity.ruleID];
      }
      if (ruleStructure.dictionary.hasOwnProperty(ruleIdentity.ruleID)) {
        ruleIndex = ruleStructure.index.find((rule, index) => rule.humanID === ruleIdentity.ruleID).index;
      }
    }

    if (ruleIndex !== null) {
      if (hasIndex && ruleIndex !== ruleIdentity.ruleIndex) {
        throw Error('Provided ruleIndex and ruleID do not point to the same rule');
      }
    } else if (hasIndex) {
      ruleIndex = ruleIdentity.ruleIndex;

      if (ruleIndex < 0 || ruleIndex >= ruleStructure.index.length) {
        throw Error(`Invalid ruleIndex ${ruleIndex}. No such rule index exists.`);
      }
    } else {
      throw Error('WTF');
    }

    if (this.ruleFlags.value[storeId] && this.ruleFlags.value[storeId][ruleIndex]) {
      return true;
    } else {
      return ruleStructure.index[ruleIndex];
    }
  }

  async pause() {
    const uID = await this.userIdSub.pipe(take(1)).toPromise();

    if (!uID) {
      this.authStateSub.unsubscribe();
      this.authStateSub = null;
    }
  }

  async unPause() {
    const uID = await this.userIdSub.pipe(take(1)).toPromise();

    if (!uID && !this.authStateSub) {
      this.subscribe();
    }
  }

  setLogoutCallback(name: string, callback: () => void | Promise<void>, promise?: boolean): boolean {

    if (this.preLogoutCallbacks.hasOwnProperty(name)) {
      return false;
    }
    this.preLogoutCallbacks[name] = {call: callback};

    if (promise) {
      this.preLogoutCallbacks[name].promise = promise;
    }
    return true;
  }

  async login(email: string, password: string): Promise<void> {
    if (email && password) {
      // Show loading modal
      const loadingModal = await this.modalController.create({
        component: SharedModalBasicComponent,
        componentProps: {
          modalTitle: 'Verifying Credentials',
          contextHeading: 'Logging In',
          modalTitleIcon: Icons.logIn,
          modalTitleIconColor: 'green-stroke',
          buttonClose: false,
          buttonAccept: false,
          contextDescription: [
            'Please wait as we verify your login credentials.'
          ]
        },
        cssClass: 'shared-basic-modal-css-smaller'
      });
      await loadingModal.present();

      try {
        const response = await this.fireAuth.signInWithEmailAndPassword(email, password);
        await this.unPause();
        await loadingModal.dismiss();
        this.store.dispatch(getUser())
        this.store.dispatch(getUserAccess())
        if (response.user) {
          await this.router.navigate(['home']);
        }
      } catch (e) {
        await loadingModal.dismiss();

        let modalProps = {
          buttonClose: true,
          buttonAccept: false,
          buttonCloseText: 'Close',
          modalTitleIcon: Icons.bug,
          modalTitleIconColor: 'red',
          modalTitle:'',
          contextHeading: '',
          contextDescription: [],
        };

        switch (e.code) {
          case 'auth/user-not-found':
            modalProps = {
              ...modalProps,
              modalTitle: 'User Not Found',
              contextHeading: 'No user found with the provided email address.',
              contextDescription: [
                'Please check you have entered the correct address or that your account has not been deleted.'
              ]
            };
            break;
          case 'auth/invalid-email':
            modalProps = {
              ...modalProps,
              modalTitle: 'Invalid Email Address',
              contextHeading: 'The address provided is not a valid email address.'
            };
            break;
          case 'auth/wrong-password':
            modalProps = {
              ...modalProps,
              modalTitle: 'Password Incorrect',
              contextHeading: 'The password you entered is incorrect.'
            };
            break;
          default:
            modalProps = {
              ...modalProps,
              modalTitle: 'Unknown Error Occurred',
              contextHeading: 'An unexpected error occurred.',
              contextDescription: [`Error code: ${e.code}`]
            };
            break;
        }

        const errorModal = await this.modalController.create({
          component: SharedModalBasicComponent,
          componentProps: modalProps,
          cssClass: ['shared-basic-modal-css-small']
        });
        await errorModal.present();
      }
    }
  }


  async logout(ask: boolean = true, gotToLogin: boolean = true) {
    const userId = await this.userIdSub.pipe(take(1)).toPromise();

    if (!userId) {
      return;
    }

    if (ask) {
      const componentProps: ISharedModalBasic = {
        buttonAccept: true,
        buttonAcceptText: 'Logout',
        buttonClose: true,
        buttonCloseCross: false,
        buttonCloseText: `Cancel`,
        modalTitle: 'Do You Wish To Logout?',
        modalTitleIcon: Icons.logIn,
        modalTitleIconColor: 'yellow-stroke',
        contextHeading: 'NOTE: You are about to log out of Gallix.',
        contextDescription: [
          'Please make sure all edits are submitted before logging out.',
          'You will need to re-enter your credentials to access your account again.'
        ]
      }
      const modalController: HTMLIonModalElement = await this.modalController.create({
        component: SharedModalBasicComponent, componentProps, cssClass: ['shared-basic-modal-css-small'],
      });
      await modalController.present();
      const {role} = await modalController.onDidDismiss();
      if (role !== 'modal-accept') {
        return;
      }
    }

    // console.clear();

    this.setUserAccessFlags(null);
    this.userIdSubject.next(null);
    const callBackNames = Object.keys(this.preLogoutCallbacks);

    const process = () => {
      if (callBackNames.length === 0) {
        this.fireAuth.signOut().then(() => {
          if (gotToLogin) {
            this.store.dispatch(setCurrentPageAndTab({currentSelectedPage: {currentPage: 'login', currentTab: null}}));
          }
        });
      } else {
        const name = callBackNames.pop();
        const cbPack = this.preLogoutCallbacks[name];


        if (cbPack.promise) {
          (cbPack.call as () => Promise<void>)().then(() => {
            delete this.preLogoutCallbacks[name];
            process();
          });
        } else {
          cbPack.call();
          delete this.preLogoutCallbacks[name];
          process();
        }
      }
    };
    process();
    window.location.reload();

  }

  async sendPasswordResetEmail(email: string, storeId?: string): Promise<void> {
    const userId = this.userIdSubject.value;

    const success = async () => {
      const ac = await this.alertControl.create({
        header: 'Email Sent', subHeader: 'A password reset email has been ' +
          `sent to ${email}.`, cssClass: 'custom-alert', buttons: ['ok']
      });
      await ac.present();
      await ac.onDidDismiss();
    };

    const uncertainEmail = async () => {
      try {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      } catch (error) {
        if ((error as FirebaseError).code === 'auth/user-not-found') {
          await this.stdAlert.errorAlert({type: 'USER-NOT-FOUND'});
        } else {
          await this.stdAlert.errorAlert({
            subHeader: `Error requesting password reset on ${error}`, message: `"${error.message}"`
          });
        }
      }
    };

    if (userId) {
      if (email !== userId) {
        if (storeId) {
          if (this.hasAccess(storeId, {ruleID: 'a.'}) === true) {
            await uncertainEmail();
          } else {
            await this.stdAlert.errorAlert({
              subHeader: 'You do not have permission to request an email reset ' +
                `for ${email}`, type: 'PERMISSION'
            });
          }
        }
      } else {
        await this.fireAuth.sendPasswordResetEmail(email);
        await success();
      }
    } else {
      await uncertainEmail();
    }
  }

  async accessChange(msg: Message) {

    if (msg.type !== 'ACCESS_CHANGE' || (this.authTime && msg.timestamp.getTime() < this.authTime.getTime())) {
      return;
    }
    const userId = await this.userIdSub.pipe(take(1)).toPromise();
    this.af.doc(`/user_access/${userId}`).get().toPromise()
      .then((uaD) => this.setUserAccessFlags(uaD.data() as IUserAccess));
    const ac = await this.alertControl.create({
      header: 'Your Access Permissions Have Been Updated', message: 'If you think this is a mistake please contact ' +
        'your admin.', cssClass: 'custom-alert', buttons: ['ok']
    });
    // const ac = await this.alertControl.create({
    //   header: 'User Access Updates', subHeader: 'Your access to one or more stores has been updated',
    //   message: 'Inorder for these changes to take affect you will need to sign back in',
    //   cssClass: ['custom-alert', 'warn'], buttons: ['ok'], backdropDismiss: false
    // });
    await ac.present();
    await ac.onDidDismiss();
  }

  private subscribe() {
    // this.authStateSubscription = this.fireAuth.authState.subscribe(user => {
    // this.fireAuth.idToken.subscribe(a => {
    //
    // });
    this.authStateSub = this.fireAuth.authState.subscribe(user => {
      if (user) {
        // if (user.email === 'claydenburger@gmail.com') {
        this.userIdSubject.next(user.email);
        this.af.doc(`/user_access/${user.email}`).get().toPromise()
          .then((uaD) => this.setUserAccessFlags(uaD.data() as IUserAccess));
        // } else {
        //   const alert = () => {
        //     this.alertControl.create({
        //       header: 'Lockdown For Cloud Security Update',
        //       message: '* This should only take a few hours<br>* Once complete ManageIt will be incomparably more ' +
        //         'secure<br>* This is the biggest step towards ALPHA we have had<br>* Side bonus: adding manager ' +
        //         'control of staff access and adding or removing staff will now be easy<br><br>Thanks<br>Techodactyl Support',
        //       cssClass: ['custom-alert', 'warn'], backdropDismiss: false
        //     }).then(ac => ac.present().then(() => ac.onDidDismiss().then(() => alert())));
        //   };
        //   alert();
        // }
        // user.getIdTokenResult().then(r => {
        //   const userAccess = {features: r.claims.features, storeList: r.claims.storeList, stores: r.claims.stores};
        //   this.setUserAccessFlags(userAccess);
        //   this.authTime = new Date(Date.parse(r.authTime));
        //   this.userAccessSubject.next(userAccess);
        //
        // });
      } else {
        this.userIdSubject.next(null);
      }
    }, error => console.error(error));
  }

  private setUserAccessFlags(access: IUserAccess) {
    const storeFlags: { [storeId: string]: boolean[] } = {};

    if (access) {
      access.storeList.forEach((storeId) => {
        storeFlags[storeId] = ruleStructure.index.map((rule, index) =>
          rule.index === index && Math.floor((access.stores[storeId] / Math.pow(2, index)) % 2) === 1);
      });
    }
    this.ruleFlags.next(storeFlags);
    this.userAccessSubject.next(access);
  }
}
