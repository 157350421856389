import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Store} from '@ngrx/store';
import {
  selectDepartmentsForSelectedStore,
  selectLineColours as ngpLineColours,
} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.selectors';
import {Observable} from 'rxjs';
import {selectSharedGridVisibleFieldsByCurrentPage} from '../../store/shared-grid.selectors';
import {LineColour, StockItem} from '../../../../shared-utilities/models-old/datastructures';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {updateSingleVisibleField} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {IDepartment, ISubDepartment} from '../../../../shared/shared-models/stock/departments';
import {GridHeaderMenuActionTypes} from '../../../../shared/shared-models/grid-menu/header-menu-action-types';
import {GridHeaderMenuTypeChange} from '../../../../shared/shared-models/grid-menu/header-menu-type-changes';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {
  selectDepAndSubDepForUserSelectedStore,
  selectLineColours,
} from '../../../../features/stock-manager/store/stock-manager.selectors';
import {
  setStockManagerMenuActions,
  updateStockManagerSingleVisibleField,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';

@Component({
  selector: 'app-cell-editor-select-dropdown',
  templateUrl: './cell-editor-select-dropdown.component.html',
  styleUrls: ['./cell-editor-select-dropdown.component.scss'],
})
export class CellEditorSelectDropdownComponent implements ICellEditorAngularComp, OnInit {

  @ViewChild('input', {static: true}) input: ElementRef;
  @ViewChild('selectElement', {static: true}) selectElement: ElementRef<HTMLSelectElement>;

  value: string | number;
  params: ICellEditorParams;
  width: number;
  selectorWidth: number;
  colDefField: string;
  lineColourObj = {};
  options: { key: string; description?: string; value?: string | number; }[] = [];
  currentVisibleFields: { [key: string]: boolean } = {};
  icons = Icons;
  departments: IDepartment[] = [];

  selectedStoreLineColour$: Observable<LineColour>;
  departments$: Observable<IDepartment[]>;
  visibleFields$: Observable<{ [key: string]: boolean }>;
  currentPage$: Observable<string>;
  currentPage: string;

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.subscribeToStore();
    this.adjustSelectorWidth();
  }

  agInit(params: ICellEditorParams<NGPReport | StockItem, string | number>): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
  }

  subscribeToStore(): void {
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
    switch (this.colDefField) {
      case 'lineColourCode':
        if (this.currentPage === 'ngp-report') {
          this.selectedStoreLineColour$ = this.store.select(ngpLineColours);
        } else {
          this.selectedStoreLineColour$ = this.store.select(selectLineColours);
        }
        this.selectedStoreLineColour$.subscribe((lineColours: LineColour): void => {
          this.lineColourObj = {...lineColours};
          this.options = this.getLineColours();
        });
        break;
      case 'dep':
        if (this.currentPage === 'ngp-report') {
          this.departments$ = this.store.select(selectDepartmentsForSelectedStore);
        } else {
          this.departments$ = this.store.select(selectDepAndSubDepForUserSelectedStore);
        }
        this.departments$.subscribe((departments: IDepartment[]): void => {
          this.departments = departments;
          this.options = this.getDepartments(departments);
        });
        break;
      case 'subDep':
        if (this.currentPage === 'ngp-report') {
          this.departments$ = this.store.select(selectDepartmentsForSelectedStore);
        } else {
          this.departments$ = this.store.select(selectDepAndSubDepForUserSelectedStore);
        }
        this.departments$.subscribe((departments: IDepartment[]): void => {
          this.departments = departments;
          this.options = this.getSubDepartments(departments);
        });
        break;
    }

    this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });
  }

  adjustSelectorWidth(): void {
    if (this.colDefField !== 'subDep') {
      this.selectorWidth = this.width - 60;
    } else {
      this.selectorWidth = this.width - 75;
    }
  }

  getValue(): string | number {
    this.checkVisibleFields();
    if (this.departments) {
      this.checkForError();
    }
    const type = this.params.colDef.cellDataType;
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(this.params.data as NGPReport | StockItem, this.colDefField, this.value, this.store, this.currentPage);
    if (type === 'text') {
      return this.value.toString();
    } else if (type === 'number') {
      return +this.value;
    }
  }

  checkForError(): void {
    if (this.colDefField === 'dep') {
      const containsValue = this.options.some(option => option.key === this.value);
      if (!containsValue) {
        this.value = '0';
      }
    }
    this.params.data.isError = GridUtils.checkSubDepCode(this.params, this.departments);
    GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
  }

  checkVisibleFields(): void {
    const currentVisibleFields = {...this.currentVisibleFields};
    if (this.colDefField === 'dep' && this.params.data[this.colDefField] !== this.params.data.originalValue[this.colDefField].value) {
      if (!currentVisibleFields.subDep) {
        if (this.currentPage === 'ngp-report') {
          this.store.dispatch(updateSingleVisibleField({
            colId: 'subDep',
            value: true,
          }));
        } else {
          this.store.dispatch(updateStockManagerSingleVisibleField({
            colId: 'subDep',
            value: true,
          }));
        }

        this.store.dispatch(setStockManagerMenuActions({
          gridHeaderMenu: {
            action: GridHeaderMenuActionTypes.editing,
            colId: 'subDep',
            typeOfChange: GridHeaderMenuTypeChange.isEditing,
            value: true,
          },
        }));
      }
    }

    if (this.colDefField === 'subDep') {
      if (!currentVisibleFields.dep) {
        const containsValue = this.options.some(
          (option: { description: string; key: string; value?: string | number; }) => option.key === this.value);
        if (!containsValue) {
          if (this.currentPage === 'ngp-report') {
            this.store.dispatch(updateSingleVisibleField({
              colId: 'dep',
              value: true,
            }));
          } else {
            this.store.dispatch(updateStockManagerSingleVisibleField({
              colId: 'dep',
              value: true,
            }));
          }
          this.store.dispatch(setStockManagerMenuActions({
            gridHeaderMenu: {
              action: GridHeaderMenuActionTypes.editing,
              colId: 'dep',
              typeOfChange: GridHeaderMenuTypeChange.isEditing,
              value: true,
            },
          }));
        }
      }
    }
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  refresh(params: ICellEditorParams<NGPReport | StockItem, string | number>): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  getDepartments(departments: IDepartment[]): { key: string; description?: string; value?: string | number; }[] {
    const deps: { key: string; description?: string; value?: string | number; }[] = [];
    departments.forEach((department: IDepartment): void => {
      deps.push({key: department.dep.toString(), description: department.name});
    });
    return deps.sort((a, b) => a.description > b.description ? 1 : -1);
  };

  getSubDepartments(departments: IDepartment[]): { key: string; description?: string; value?: string | number; }[] {
    if (this.params.data.dep !== '0') {
      const subDeps: { key: string; description?: string; value?: string | number; }[] = [];
      const dep = departments.find((department: IDepartment): boolean => department.dep === this.params.data.dep);
      dep.subDeps?.forEach((sub: ISubDepartment): void => {
        subDeps.push({key: sub.subDep.toString(), description: sub.name});
      });
      return subDeps.sort((a, b) => a.description > b.description ? 1 : -1);
    } else {
      return [];
    }
  }

  getLineColours(): { description: string, key: string, value: string | number }[] {
    return Object.keys(this.lineColourObj)
      .map((key: string) => ({
        key,
        description: this.lineColourObj[key].description,
        value: this.lineColourObj[key].value,
      }))
      .sort((a, b) => a.description > b.description ? 1 : -1);
  }

  onSelectionChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.value = inputElement.value as unknown as number;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateErrorAndForceRefresh(this.params, this.departments);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    if (this.departments) {
      this.checkForError();
    }
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.colDefField, this.store, this.currentPage);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

}
