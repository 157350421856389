import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {DataAdditionalIcons} from '../../../../shared-utilities/models-old/ngp-report-grid/data-additional-icons';
import {Icons} from '../../../shared-icons/icons';

/**
 * CellRendererIconsComponent renders custom HTML within an AG Grid Cell
 * The component displays icons according to additional data provided in the
 * column definitions, and whether it is set to be displayed within the object.
 */
@Component({
  selector: 'app-cell-renderer-icons',
  templateUrl: './cell-renderer-icons.component.html',
  styleUrls: ['./cell-renderer-icons.component.scss'],
})
export class CellRendererIconsComponent implements ICellRendererAngularComp {

  readonly icons: typeof Icons = Icons;
  params: ICellRendererParams;
  additionalData: DataAdditionalIcons
    & {
    costMiss: boolean;
    created: boolean;
    isEdited: boolean;
    error: boolean;
  };

  agInit(
    params: ICellRendererParams<any, any, any> &
      { dataAdditional: DataAdditionalIcons },
  ): void {
    this.assignComponentProperties(params);
    this.params = {...params};
  }

  refresh(params: ICellRendererParams<any, any, any> &
    { dataAdditional: DataAdditionalIcons }): boolean {
    this.assignComponentProperties(params);
    return true;
  }

  /**
   * Assign the incoming data to component scope properties.
   *
   * @param params The parameters that are sent through from AG Grid, with dataAdditionalIcon properties
   */
  private assignComponentProperties(
    params: ICellRendererParams<any, any, any> &
      { dataAdditional: DataAdditionalIcons }): void {
    this.additionalData = params?.dataAdditional ? {
      ...params.dataAdditional,
      costMiss: params?.data?.costMiss || false,
      created: params?.data?.created || false,
      isEdited: params?.data?.isEdited || false,
      error: params?.data?.error || false,
    } : null;
  }

}
