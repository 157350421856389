import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectSelectedUserStores} from '../../../../../features-as-modules/feature-core/store/core.selectors';
import {Observable} from 'rxjs';
import {IStore} from '../../../../../shared/shared-models/store/store';
import {DisabledRules, LineColour, TableNavSettings} from '../../../../../shared-utilities/models-old/datastructures';
import {selectLineColour, selectNavSettings, selectRulesDoc} from '../../../store/settings.selectors';
import {getItemDisablingRules, getLineColour, getTableNavSettings} from '../../../store/settings.actions';

@Component({
  selector: 'app-settings-general-grid-container',
  templateUrl: './settings-general-grid-container.component.html',
  styleUrls: ['./settings-general-grid-container.component.scss'],
})
export class SettingsGeneralGridContainerComponent implements OnInit {

  currentStore$: Observable<IStore>;
  disabledRules$: Observable<DisabledRules>;
  lineColours$: Observable<LineColour>;

  constructor(
    private readonly store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getLineColour());
    this.store.dispatch(getItemDisablingRules());
    this.currentStore$ = this.store.select(selectSelectedUserStores);
    this.disabledRules$ = this.store.select(selectRulesDoc);
    this.lineColours$ = this.store.select(selectLineColour);
  }

}
