import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import * as CoreActions from './core.actions';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IUser} from '../../../shared/shared-models/user-access/user';
import {IStore} from '../../../shared/shared-models/store/store';
import {INavigationPage} from '../../../shared-utilities/models-old/page/page';
import {GridUtils} from '../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {getModuleNameFromRouterModule} from '../../../shared/shared-utils/navigation/navigation-url.utils';

import {IUserAccess} from '../../../shared/shared-models/user-access/user-access';

export interface CoreState extends IInitialState {
  // Modules --------------------------------
  isModuleAdminInfoLoading: boolean;
  isModuleAdminInternalLoading: boolean;
  isModuleAutoOrderingLoading: boolean;
  isModuleBarcodeScannerLoading: boolean;
  isModuleCoreLoading: boolean;
  isModuleDashboardLoading: boolean;
  isModuleHomeLoading: boolean;
  isModuleLoginLoading: boolean;
  isModuleNgpReportLoading: boolean;
  isModuleShelfTalkersLoading: boolean;
  isModuleSignupLoading: boolean;
  isModuleStockLoading: boolean;
  isModuleStockMatchLoading: boolean;
  isModuleStockUpdatesLoading: boolean;
  isModuleTestingLoading: boolean;
  isModuleUserAccessLoading: boolean;
  // User -----------------------------------
  isUserLoading: boolean;
  user: IUser;
  ita: boolean;
  isUserAccessLoading: boolean;
  userAccess: IUserAccess;
  // User Stores ----------------------------
  isUserStoresLoading: boolean;
  userStores: IStore[];
  userSelectedStore: IStore;
  currentSelectedPage: INavigationPage;
  // Images ---------------------------------
  images: { [image: string]: string }
  // Messages -------------------------------
  isUserMessageCountLoading: boolean;
  userMessagesCount: number;
}

export const initialCoreState: CoreState = {
  // Initial --------------------------------
  errors: [],
  // Modules --------------------------------
  isModuleAdminInfoLoading: false,
  isModuleAdminInternalLoading: false,
  isModuleAutoOrderingLoading: false,
  isModuleBarcodeScannerLoading: false,
  isModuleCoreLoading: false,
  isModuleDashboardLoading: false,
  isModuleHomeLoading: false,
  isModuleLoginLoading: false,
  isModuleNgpReportLoading: false,
  isModuleShelfTalkersLoading: false,
  isModuleSignupLoading: false,
  isModuleStockLoading: false,
  isModuleStockMatchLoading: false,
  isModuleStockUpdatesLoading: false,
  isModuleTestingLoading: false,
  isModuleUserAccessLoading: false,
  // User -----------------------------------
  isUserLoading: false,
  user: null,
  ita: false,
  isUserAccessLoading: false,
  userAccess: null,
  // User Stores ----------------------------
  isUserStoresLoading: false,
  userStores: null,
  userSelectedStore: null,
  currentSelectedPage: {currentPage: 'home', currentTab: null},
  // Images ---------------------------------
  images: {},
  // Messages -------------------------------
  isUserMessageCountLoading: false,
  userMessagesCount: 0
};

const createCoreReducer: ActionReducer<CoreState> = createReducer(
  initialCoreState,
  // ====================================================================================================
  // Get User
  // ====================================================================================================
  on(CoreActions.getUser, (state: CoreState) => ({
    ...state,
    isUserLoading: true,
    user: null
  })),
  on(CoreActions.getUserSuccess, (state: CoreState, {user}) => ({
    ...state,
    isUserLoading: false,
    user
  })),
  on(CoreActions.getUserFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserLoading: false,
    user: null,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserLoading: isLoading
  })),
  // ====================================================================================================
  // Get User Access
  // ====================================================================================================
  on(CoreActions.getUserAccess, (state: CoreState) => ({
    ...state,
    isUserAccessLoading: true,
    userAccess: null,
  })),
  on(CoreActions.getUserAccessSuccess, (state: CoreState, {userAccess}) => ({
    ...state,
    isUserAccessLoading: false,
    userAccess
  })),
  on(CoreActions.getUserAccessFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserAccessLoading: false,
    userAccess: null,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserAccessLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserAccessLoading: isLoading
  })),
  // ====================================================================================================
  // Set ITA (Is Techo Admin)
  // ====================================================================================================
  on(CoreActions.setITA, (state: CoreState, {ita}) => ({
    ...state,
    ita
  })),
  // ====================================================================================================
  // Get User Stores
  // ====================================================================================================
  on(CoreActions.getUserStores, (state: CoreState) => ({
    ...state,
    isUserStoresLoading: true,
    userStores: null
  })),
  on(CoreActions.getUserStoresSuccess, (state: CoreState, {storeObject}) => {
    const userStores = GridUtils.mapStoreObjectToStore(storeObject);
    return {
      ...state,
      isUserStoresLoading: false,
      userStores,
      userSelectedStore: userStores.length > 0 ? userStores[0] : null
    };
  }),
  on(CoreActions.getUserStoresFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserStoresLoading: false,
    userStores: null,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserStoresLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserStoresLoading: isLoading
  })),
  // ====================================================================================================
  // Set User Selected Store
  // ====================================================================================================
  on(CoreActions.setUserSelectedStore, (state: CoreState, {selectedStore}) => ({
    ...state,
    userSelectedStore: selectedStore
  })),
  // ====================================================================================================
  // Set Use Last Visited Page
  // ====================================================================================================
  on(CoreActions.setCurrentPageAndTab, (state: CoreState, {currentSelectedPage}) => ({
    ...state,
    currentSelectedPage
  })),
  // ====================================================================================================
  // Set Is Module Loading
  // ====================================================================================================
  on(CoreActions.setIsModuleLoading, (state: CoreState, {event, isLoading}) => {
    const module = getModuleNameFromRouterModule(event);
    return {
      ...state,
      [`isModule${module}Loading`]: isLoading
    }
  }),
  // ====================================================================================================
  // Get Image URL
  // ====================================================================================================
  on(CoreActions.getImageURL, (state: CoreState, {path, image}) => {
    const images = {...state.images};
    if (images[image]) {
      delete images[image];
    }
    return {
      ...state,
      images
    }
  }),
  on(CoreActions.getImageURLSuccess, (state: CoreState, {url, image}) => {
    const images = {...state.images};
    images[image] = url;
    return {
      ...state,
      images
    }
  }),
  on(CoreActions.getImageURLFailure, (state: CoreState, {error}) => {
    return {
      ...state,
      errors: [...state.errors, error]
    }
  }),
  // ====================================================================================================
  // User Messages
  // ====================================================================================================
  on(CoreActions.getUserMessageCount, (state: CoreState) => ({
    ...state,
    isUserMessageCountLoading: true,
    userMessagesCount: 0
  })),
  on(CoreActions.getUserMessageCountSuccess, (state: CoreState, {messageCount}) => ({
    ...state,
    isUserMessageCountLoading: false,
    userMessagesCount: messageCount
  })),
  on(CoreActions.getUserMessageCountFailure, (state: CoreState, {error}) => ({
    ...state,
    isUserMessageCountLoading: false,
    userMessagesCount: 0,
    errors: [...state.errors, error]
  })),
  on(CoreActions.setIsUserMessageCountLoading, (state: CoreState, {isLoading}) => ({
    ...state,
    isUserMessageCountLoading: isLoading
  })),
);

export const coreReducer = (state: CoreState, action: Action): CoreState => createCoreReducer(state, action);
