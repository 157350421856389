import {SettingsUserContainerComponent} from './settings-user-container/settings-user-container.component';
import {SettingsPreviewColumnsComponent} from './settings-preview-columns/settings-preview-columns.component';

export const SETTINGS_USER_CONTAINER_COMPONENT = SettingsUserContainerComponent
export const SETTINGS_USER_PREVIEW_COLUMNS_COMPONENT = SettingsPreviewColumnsComponent

export const USER_SETTINGS_COMPONENT = [
  SETTINGS_USER_CONTAINER_COMPONENT,
  SETTINGS_USER_PREVIEW_COLUMNS_COMPONENT,
]
