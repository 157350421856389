import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as NGPReportActions from './ngp-report.actions';
import {catchError, map, mergeMap, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {forkJoin, from, of} from 'rxjs';
import {
  DisabledRules,
  LineColour,
  StockItem,
  Supplier,
  VatRates,
} from '../../../shared-utilities/models-old/datastructures';
import {Action, select, Store} from '@ngrx/store';
import {selectSelectedUserStores, selectUserStores} from '../../feature-core/store/core.selectors';
import {NGPReport} from '../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {
  selectAllVatRates,
  selectItemDisablingRules,
  selectNGPReports,
  selectSuppliersByUserSelectedStore,
} from './ngp-report.selectors';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {IStore} from '../../../shared/shared-models/store/store';
import {ReportUtils} from '../../../shared-utilities/utils-old/shared-utils-old/report-utils';
import {CollectionSharedStockService} from '../../../shared/shared-services/firebase/collection-shared-stock.service';
import {selectNGPReportsPriceBanded} from '../../../shared-modules/shared-grid/store/shared-grid.selectors';

@Injectable()
export class NgpReportEffects {
  // ====================================================================================================
  // Get Line Colours
  // ====================================================================================================

  // '[NGP Report] Get Line Colours',
  getLineColours$ = createEffect(() => this.actions$.pipe(ofType(NGPReportActions.getLineColours),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    mergeMap(([{pageStoreDocument}, selectedStore]) =>
      from(this.firebaseService.getStoreDataDoc(pageStoreDocument, selectedStore.storeId))
        .pipe(map((lineColours: LineColour) => NGPReportActions.getLineColoursSuccess({
            lineColours,
            selectedStore,
          })),
          catchError((error: IError) =>
            of(NGPReportActions.getLineColoursFailure({error})),
          ),
        ),
    )),
  );

  // ====================================================================================================
  // Get Stock Items
  // ====================================================================================================

  // '[NGP Report] Get Stock Item'
  getStockItems$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.getStockItem),
    withLatestFrom(this.store.pipe(select(selectSuppliersByUserSelectedStore))),
    switchMap(([getStockItemAction, selectedStoreSuppliers]) => {
      const actions: Action[] = [];
      if (Object.keys(selectedStoreSuppliers).length > 0) {
        actions.push(NGPReportActions.getStockItemWithExistingSuppliers({
          ...getStockItemAction, suppliers: selectedStoreSuppliers,
        }));
      } else {
        actions.push(NGPReportActions.getStockItemWithoutExistingSuppliers(getStockItemAction));
      }
      return actions;
    })),
  );

  // '[NGP Report] Get Stock Item With Existing Suppliers'
  getStockItemWithExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItemWithExistingSuppliers),
      mergeMap((
        {store, firebaseQueries, idField, suppliers}) =>
        this.collectionSharedStockService.getStockItemsCast(store, firebaseQueries, idField)
          .pipe(
            map((stockItemsRaw: StockItem[]) => {
                const stockItems = stockItemsRaw.map((item: StockItem): StockItem => ({
                  ...item,
                  newlyAdded: true,
                  storeId: store.storeId,
                  store: store.name,
                  suppName: suppliers[item.regularSuppCode]?.name || null,
                }));
                return NGPReportActions.getStockItemSuccess({stockItems, store});
              },
              catchError((error: IError) =>
                of(NGPReportActions.getStockItemFailure({error})),
              ),
            ),
          ),
      ),
    ),
  );

  // '[NGP Report] Get Stock Item Without Existing Suppliers'
  getStockItemWithoutExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStockItemWithoutExistingSuppliers),
      mergeMap((
        {store, firebaseQueries, idField}) =>
        this.collectionSharedStockService.getStockItemsCast(store, firebaseQueries, idField)
          .pipe(switchMap((ngpReports: StockItem[]) => from(this.firebaseService.getSuppliers(store.storeId))
            .pipe(switchMap((rawSuppliers: { [p: string]: Supplier }) => {
                const suppliers = this.createSupplierMap(rawSuppliers);
                const stockItems = ngpReports.map((item: StockItem): StockItem => ({
                  ...item,
                  newlyAdded: true,
                  storeId: store.storeId,
                  store: store.name,
                  suppName: suppliers[item.regularSuppCode]?.name || null,
                }));
                return from([
                  NGPReportActions.getStoreSuppliersByStoreIdSuccess({suppliers, storeId: store.storeId}),
                  NGPReportActions.getStockItemSuccess({stockItems, store})]);
              }),
              catchError((error: IError) => of(
                NGPReportActions.getStockItemFailure({error}),
                NGPReportActions.getStoreSuppliersByStoreIdFailure({error})),
              ),
            )),
          ),
      ),
    ),
  );

  // ====================================================================================================
  // Get Items Disabling Rules
  // ====================================================================================================

  // '[NGP Report] Get Stock Item'
  getStockItemDisablingRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NGPReportActions.getStockItem,
        NGPReportActions.getStockItemDisablingRules,
      ),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([action, selectedStore]) => {
        return this.firebaseService
          .subStoreDataDoc('stock_item_deletion_rules', selectedStore.storeId, 'operational')
          .pipe(
            map((rulesDoc: DisabledRules) => {
              return NGPReportActions.getStockItemDisablingRulesSuccess({store: selectedStore, rulesDoc});
            }),
            catchError((errors: IError) =>
              of(NGPReportActions.getStockItemDisablingRulesFailure({errors})),
            ),
          );
      }),
    ),
  );

  // ====================================================================================================
  // Set Disabled/Enabled items
  // ====================================================================================================
  setItemDisabled$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setItemDisabledStatus),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStores)),
      this.store.pipe(select(selectItemDisablingRules)),
    ),
    switchMap(([action, currentSelectedStore, disabledRules]) => {
      const actions: Action[] = [];
      const updatedNgpReport = {...action.ngpReport};
      ReportUtils.enableReport(updatedNgpReport, disabledRules, action.enable);
      return [NGPReportActions.setItemDisabledWithStoreID({
        ngpReport: updatedNgpReport,
        store: currentSelectedStore,
      })];
    })),
  );
  // ====================================================================================================
  // Set Filters and Tools
  // ====================================================================================================
  // '[NGP Report] Set NGP Report Filters and Tools With Store'
  setNGPReportFiltersAndToolsWithStore$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setNGPReportFiltersAndTools),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    map(([filtersAndTools, store]) =>
      NGPReportActions.setNGPReportFiltersAndToolsWithStore({
        filtersAndTools: filtersAndTools.filtersAndTools,
        store,
      }),
    )),
  );

  // ====================================================================================================
  // Set Grid Menu Action
  // ====================================================================================================

  //  '[NGP Report] Set NGP Report Menu Actions per Column with Store Details',
  setNGPReportMenuActionsWithStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNGPReportMenuActions),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      switchMap(([action, userStores]) => [NGPReportActions.setNGPReportMenuActionsWithStore({
        ...action,
        store: userStores,
      })]),
    ),
  );

//===============================================================================================================
// Get Store Departments
//===============================================================================================================
  // '[NGP Report] Get Store Departments'
  fetchStoreDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getStoreDepartments),
      mergeMap(({store}) => this.firebaseService.getDepartmentsByStore(store)
        .pipe(map((departments: IDepartment[]) => NGPReportActions.getStoreDepartmentsSuccess({store, departments})),
          catchError((error) =>
            of(NGPReportActions.getStoreDepartmentsFailure({error})),
          ),
        ),
      ),
    ),
  );

//===============================================================================================================
// Remove Selected Items From NGP Report
//===============================================================================================================
  removeSelectedNgpReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.removeSelectedNgpReports),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStores)),
      ),
      mergeMap(([{
        selectedReports,
        itemsToUpdate,
      }, store]) => {
        let promises: Promise<void>[] = [];
        // Update items in Firebase if needed
        if (Object.keys(itemsToUpdate).length) {
          promises.push(new Promise<void>((resolve) => this.firebaseService.stockUpdate(store.storeId, itemsToUpdate)
            .then((): void => {
              resolve();
            })));
        }
        // Remove selected reports and update stock tags in Firebase
        const stockIds = selectedReports.map((report: NGPReport) => report.stockId);
        promises.push(this.firebaseService.updateStockTags(store.storeId, stockIds, 'in_ngp', true));
        return from(Promise.all(promises)).pipe(map(() => {
          return NGPReportActions.removeSelectedNgpReportsWithStoreID({
            selectedReports, storeId: store.storeId,
          });
        }));
      }),
    ),
  );


  updateSingleNGPReport$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.updateSingleNGPReport),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    switchMap(([action, currentSelectedStore]) => {
      const actions: Action[] = [];
      return [NGPReportActions.updateSingleNGPReportWithStoreId({
        ngpReport: action.ngpReport,
        storeId: currentSelectedStore.storeId,
      })];
    })),
  );

  //'[NGP Report][Price Banding] Update The Price NGP and GpDiff for NGP Reports With PriceBanding'
  setApplyPriceBandingToNGPReportItems$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setApplyPriceBandingToNGPReportItems),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStores)),
      this.store.pipe(select(selectNGPReportsPriceBanded)),
    ),
    switchMap(([_, currentSelectedStore, ngpReports]) => {
      const ngpReportsByStockId = ngpReports.reduce(
        (acc: { [stockId: string]: NGPReport }, ngpReport: NGPReport) => {
          acc[ngpReport.stockId] = ngpReport;
          return acc;
        },
        {},
      );
      return [NGPReportActions.setApplyPriceBandingToNGPReportItemsAtStoreId({
        ngpReports: ngpReportsByStockId,
        store: currentSelectedStore,
      })];
    })),
  );

  updateIsSelectedForSingleNGPReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.updateIsSelectedForSingleNGPReport),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStores)),
        this.store.pipe(select(selectNGPReports)),
      ),
      switchMap(([action, currentSelectedStore, mostRecentNGPReport]) => {
        const matchingNGPItem =
          mostRecentNGPReport[currentSelectedStore.storeId]?.[action.ngpReport.stockId];

        const updatedNGPItem = {...matchingNGPItem};
        updatedNGPItem.isSelected = action.ngpReport.isSelected;

        return [
          NGPReportActions.updateIsSelectedForSingleNGPReportWithStoreId({
            ngpReport: updatedNGPItem,
            storeId: currentSelectedStore.storeId,
          }),
        ];

      }),
    ),
  );


  updateAllNGPReport$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.updateAllNGPReports),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    switchMap(([action, currentSelectedStore]) => {
      return [
        NGPReportActions.updateAllNGPReportsWithStoreID({
          ngpReports: [...action.ngpReports],
          storeId: currentSelectedStore.storeId,
        }),
      ];
    }),
  ));

  updateNewlyAddedItemsVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setNewlyAddedItemsVisibility),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    map(([_, currentSelectedStore]) => NGPReportActions.setNewlyAddedItemsVisibilityWithStoreID({
        storeId: currentSelectedStore.storeId,
      }),
    )),
  );

  updateNGPReportGridColDefs$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setNGPReportGridColDefs),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    map(([action, currentSelectedStore]) => NGPReportActions.setNGPReportGridColDefsWithStoreID({
      colDefs: action.colDefs, storeId: currentSelectedStore.storeId,
    }))));

  setNGPReportVisibility$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.setNGPReportVisibility),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    map(([action, currentSelectedStore]) => NGPReportActions.setNGPReportVisibilityWithStoreID({
        colDef: action.colDef, menuData: action.menuData, storeId: currentSelectedStore.storeId,
      }),
    )),
  );

  //===============================================================================================================
// make changes to Price incl || NPG || Gp Diff when one of them is changed
//===============================================================================================================

  updatePriceGpForNgpReport$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.updatePriceGpForNgpReport),
    withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
    switchMap(([action, currentSelectedStore]) => {
      const storeId = currentSelectedStore.storeId;
      return [
        NGPReportActions.updatePriceGpForNgpReportWithStoreID({
          ngpReport: action.ngpReport,
          field: action.field,
          storeId: storeId,
        }),
      ];
    }),
  ));

  getVatRateConversion$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.getVatRateConversion),
    withLatestFrom(
      this.store.pipe(select(selectUserStores)),
      this.store.pipe(select(selectAllVatRates)),
    ),
    switchMap(([action, userStores, vatRates]) => {
      const actions: Action[] = [];
      if (Object.keys(vatRates).length > 0) {
        actions.push(NGPReportActions.getVatRateConversionWithExistingVatRates({
          userStores,
          vatRates: vatRates,
        }));
      } else {
        actions.push(NGPReportActions.getVatRateConversionWithoutExistingVatRates({
          userStores,
        }));
      }
      return actions;
    }),
  ));

  getVatRateConversionWithExistingVatRates$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.getVatRateConversionWithExistingVatRates),
    map(({userStores, vatRates}) => {
      const vatRatesResult = userStores.reduce((acc, userStore) => {
        acc[userStore.storeId] = vatRates[userStore.storeId];
        return acc;
      }, {});
      return NGPReportActions.getVatRateConversionSuccess({vatRates: vatRatesResult});
    }),
  ));

  getVatRateConversionWithoutExistingVatRates$ = createEffect(() => this.actions$.pipe(
    ofType(NGPReportActions.getVatRateConversionWithoutExistingVatRates),
    switchMap(({userStores}) => {
      const vatRatesObservables = userStores.map((userStore: IStore) =>
        this.firebaseService.subStoreDoc('data/singular_documents/vat_rates', userStore.storeId)
          .pipe(
            take(1),
            map((vr: VatRates) => ({storeId: userStore.storeId, vatRate: vr})),
          ),
      );
      return forkJoin(vatRatesObservables).pipe(
        map((vatRatesArray) => {
          const vatRates = vatRatesArray.reduce((acc, {storeId, vatRate}) => {
            acc[storeId] = vatRate;
            return acc;
          }, {});
          return NGPReportActions.getVatRateConversionSuccess({vatRates});
        }),
        catchError((error) => of(NGPReportActions.getVatRateConversionFailure({error}))),
      );
    }),
  ));

//===============================================================================================================
// Set the NGP Preview Column Data in firebase
//===============================================================================================================

  setNgpPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.setNgpPreviewColumns),
      switchMap((action) => {
        return from(this.firebaseService.setUserSingularDocuments('ngp_edits_preview', action.columnData)).pipe(
          map(() => NGPReportActions.setNgpPreviewColumnsSuccess({columnData: action.columnData})),
          catchError((error: IError) => of(NGPReportActions.setNgpPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );

  getNgpPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NGPReportActions.getNgpPreviewColumns),
      switchMap(() => {
        return from(this.firebaseService.getUserDocument('ngp_edits_preview')).pipe(
          map((columnData: {
            [key: number]: string
          }) => NGPReportActions.setNgpPreviewColumnsSuccess({columnData: columnData ? columnData : {}})),
          catchError((error: IError) => of(NGPReportActions.setNgpPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private collectionSharedStockService: CollectionSharedStockService,
    private readonly store: Store,
  ) {
  }

  private createSupplierMap(suppliers: { [p: string]: Supplier }): { [key: string]: Supplier } {
    const supplierMap = {};
    for (const key in suppliers) {
      if (suppliers.hasOwnProperty(key)) {
        supplierMap[key] = suppliers[key];
      }
    }
    return supplierMap;
  }

}
