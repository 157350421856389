import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {FirebaseService} from '../../../shared-utilities/services-old/firebase.service';
import * as StockManagerActions from '../store/stock-manager.actions';
import {catchError, filter, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {
  DisabledRules,
  LineColour,
  sItemIntToKey,
  sItemKeyToInt,
  StockItem,
  Supplier,
} from '../../../shared-utilities/models-old/datastructures';
import {IError} from '../../../shared-utilities/models-old/error/error';
import {from, Observable, of} from 'rxjs';
import {Action, select, Store} from '@ngrx/store';
import {TypeSenseService} from '../../../shared/shared-services/type-sense/type-sense.service';
import {IPaginationData} from '../../../shared/shared-models/pagination/pagination-data';
import {
  selectAllStockItemsForCurrentSelectedStore,
  selectEditedItems, selectEnablePriceBandingForStockManager,
  selectItemDisablingRules,
  selectPaginationDataForUserSelectedStore,
  selectTypesenseQueryData,
} from './stock-manager.selectors';
import {IFacetCounts, TypeSenseSearchResponse} from '../../../shared/shared-models/type-sense/type-sense-types';
import {ISearchableFields} from '../../../shared/shared-models/type-sense/default-searchable-fields';
import {IDepartment} from '../../../shared/shared-models/stock/departments';
import {
  createFacetCountsMap,
  mapTypeSenseObjectToGeneric,
} from '../../../shared/shared-utils/typesense-utils/typesense.utils';
import {createMapFromObjectsObject, getDeepCopyOfObject} from '../../../shared/shared-utils/object/object.utils';
import {StockFunctions} from '../../../shared-utilities/functions-old/stock-functions';
import {calculateGPFromPrice} from '../../../shared/shared-utils/calculations/calculations-stock';
import {selectSelectedUserStores} from '../../../features-as-modules/feature-core/store/core.selectors';
import {
  applyPriceBanding,
  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
} from '../../../shared/shared-utils/price/price-banding.utils';
import {
  selectPriceBandingForUserSelectedStore,
  selectSharedGridVatRateBySelectedStore,
} from '../../../shared-modules/shared-grid/store/shared-grid.selectors';

@Injectable()
export class StockManagerEffects {

  // Call on Init for each store
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  // '[Stock Manager][Line Colour] Get Line Colours',
  getLineColours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getLineColours),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      mergeMap(([{pageStoreDocument}, selectedStore]) =>
        from(this.firebaseService.getStoreDataDoc(pageStoreDocument, selectedStore.storeId)).pipe(
          mergeMap((lineColours: LineColour) => {
            return [
              StockManagerActions.getLineColoursSuccess({lineColours, selectedStore}),
              StockManagerActions.getAllStockItems({idField: '', currentPage: 1, pageSize: 20}),
            ];
          }),
          catchError((error: IError) => {
            return of(StockManagerActions.getLineColoursFailure({error, store: selectedStore}));
          }),
        ),
      ),
    ),
  );

  // '[Stock Manager][Item Disabling] Get Stock Items Disabling Rules',
  getStockItemDisablingRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStockItemDisablingRules),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([_, queryData]) => {
        const {store} = queryData;
        return this.firebaseService
          .subStoreDataDoc('stock_item_deletion_rules', store.storeId, 'operational')
          .pipe(
            map((rulesDoc: DisabledRules) => {
              return StockManagerActions.getStockItemDisablingRulesSuccess({store, rulesDoc});
            }),
            catchError((errors: IError) =>
              of(StockManagerActions.getStockItemDisablingRulesFailure({errors})),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Stock Item
  // =======================================================================

  // '[Stock Manager][Stock Item] Get All Stock Items',
  getAllStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize}, queryData]) => {
        const {
          store,
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
          suppliers,
        } = queryData;

        if (suppliers && Object.keys(suppliers)?.length > 0) {
          return of(
            StockManagerActions.getAllStockItemsWithExistingSuppliers({
              idField,
              currentPage,
              pageSize,
              store,
              selectedFields: searchableFields,
              selectedStoreSuppliers: suppliers,
            }),
            StockManagerActions.getNextPageForStockItems(),
          );
        } else {
          return of(
            StockManagerActions.getAllStockItemsWithoutExistingSuppliers({
              idField,
              currentPage,
              pageSize,
              store,
              selectedFields: searchableFields,
            }),
            StockManagerActions.getNextPageForStockItems(),
          );
        }
      }),
    ),
  );

  //'[Stock Manager][Stock Item] Get All Stock Items When Suppliers Exist ',
  getAllStockItemsWithExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItemsWithExistingSuppliers),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize, store, selectedFields}, queryData]) => {
        const {sortType, filters, advancedFilterGroup} = queryData;

        return this.typeSenseService
          .searchStockItems(
            idField,
            currentPage,
            pageSize,
            store,
            selectedFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage,
                searchedValue: '',
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                disabled: false,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              return [
                StockManagerActions.getAllStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                  facetCounts,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getAllStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  //'[Stock Manager][Stock Item] Get All Stock Items When Suppliers Do Not Exist ',
  getAllStockItemsWithoutExistingSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getAllStockItemsWithoutExistingSuppliers),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField, currentPage, pageSize, store, selectedFields}, queryData]) => {
        const {sortType, filters, advancedFilterGroup} = queryData;

        return this.typeSenseService
          .searchStockItems(idField, currentPage, pageSize, store, selectedFields, filters, sortType, advancedFilterGroup)
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) =>
              from(this.firebaseService.getSuppliers(store.storeId)).pipe(
                map((rawSuppliers: { [key: string]: Supplier }) => {
                  const suppliers = createMapFromObjectsObject<Supplier>(rawSuppliers, 'name');
                  const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
                  const manipulatedSuppliers = Object.keys(suppliers).reduce((acc, key) => {
                    acc[key] = {
                      name: rawSuppliers[key].name,
                      value: false,
                    };
                    return acc;
                  }, {} as ISearchableFields);

                  const paginationData: IPaginationData = {
                    totalItems: stockItemsRaw.found,
                    pageSize,
                    currentPage,
                    searchedValue: '',
                  };

                  const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
                  const stockItems = mappedStockItems.map((item: StockItem): StockItem => ({
                    ...item,
                    storeId: store.storeId,
                    store: store.name,
                    isEdited: false,
                    isError: false,
                  }));

                  stockItems.forEach((item: StockItem) => {
                    const stockItem = calculateGPFromPrice(item, store, 2);
                    item.disabled = false;
                    item.nominalGP = +stockItem.nominalGP;
                  });

                  return [
                    StockManagerActions.getSuppliersForStockManagerSuccess({
                      suppliers: manipulatedSuppliers,
                      store,
                    }),
                    StockManagerActions.getAllStockItemsSuccess({
                      stockItems,
                      store,
                      paginationData,
                      facetCounts,
                    }),
                    StockManagerActions.getNextPageForStockItems(),
                  ];
                }),
              ),
            ),
            mergeMap((actions) => from(actions)),
            catchError((error: IError) =>
              from([
                StockManagerActions.getAllStockItemsFailure({error, store}),
                StockManagerActions.getSuppliersForStockManagerFailure({error, store}),
              ]),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Departments and SubDepartments
  // =======================================================================

  //'[Stock Manager][Departments] Get Store Departments For Stock Manager',
  fetchStoreDepartments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStoreDepartmentsForStockManager),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([_, queryData]) => {
        const {store} = queryData;
        return this.firebaseService.getDepartmentsByStore(store).pipe(
          map((departments: IDepartment[]) =>
            StockManagerActions.getStoreDepartmentsForStockManagerSuccess({
              store,
              departments,
            }),
          ),
          catchError((error: IError) =>
            of(StockManagerActions.getStoreDepartmentsForStockManagerFailure({error, store})),
          ),
        );
      }),
    ),
  );

  // =======================================================================
  // Suppliers
  // =======================================================================

  //'[Stock Manager][Suppliers] Set Suppliers For Stock Manager',
  setSuppliers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setSuppliersForStockManager),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{suppliers}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue, currentPage},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            currentPage,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              return StockManagerActions.setSuppliersForStockManagerSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
                suppliers,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getPaginationResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Filters and Menu Actions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  // ====================================================================================================
  // Set Grid Menu Action
  // ====================================================================================================

  //[Stock Manager][Menu Actions] Set Stock Manager Menu Actions per Column',
  setStockManagerMenuActionsWithStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockManagerMenuActions),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      switchMap(([action, queryData]) => {
        const {store} = queryData;
        let sortType = '';

        if (action.gridHeaderMenu.typeOfChange === 'isSorting') {
          switch (action.gridHeaderMenu.sort) {
            case 'sort-asc':
              sortType = `${sItemKeyToInt[action.gridHeaderMenu.colId]}:asc`;
              break;
            case 'sort-desc':
              sortType = `${sItemKeyToInt[action.gridHeaderMenu.colId]}:desc`;
              break;
            default:
              sortType = ``;
              break;
          }
        }

        if (action.gridHeaderMenu.typeOfChange === 'isSorting') {
          const actions = [
            StockManagerActions.setStockManagerMenuActionsWithStore({
              ...action,
              store,
            }),
            StockManagerActions.setStockManagerSortTye({sortType}),
          ];
          return from(actions);
        } else if (action.gridHeaderMenu.typeOfChange === "isPriceBanding") {
          const actions = [
            StockManagerActions.setStockManagerMenuActionsWithStore({
              ...action,
              store: store,
            }),
            StockManagerActions.togglePriceBanding(),
          ];
          return from(actions);
        } else {
          return of(
            StockManagerActions.setStockManagerMenuActionsWithStore({
              ...action,
              store,
            }),
          );
        }
      }),
    ),
  );

  //'[Stock Manager][Menu Actions] Set Stock Manager Sort Type',
  getAllStockItemsSorted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockManagerSortTye),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{sortType}, queryData]) => {
        const {
          store,
          paginationData: {searchedValue, pageSize},
          searchableFields,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            1,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: 1,
                searchedValue,
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              return StockManagerActions.getAllStockItemsSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getAllStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Filters
  // =======================================================================

// '[Stock Manager][Filters] Set Stock Item Filters',
  setFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockItemFilters),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{filters}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue, currentPage},
          searchableFields,
          sortType,
          advancedFilterGroup,
        } = queryData;
        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            currentPage,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              return StockManagerActions.setStockItemFiltersSuccess({
                stockItems,
                store,
                paginationData,
                facetCounts,
                filters,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.setStockItemFiltersFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Editing Items Functions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  //'[Stock Manager] set Edited Item',
  setEditedItems$ = createEffect(() => this.actions$.pipe(
      ofType(StockManagerActions.setEditedItem),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStores)),
        this.store.pipe(select(selectEditedItems)),
      ),
      switchMap(([{stockItem}, currentSelectedStore, editedItems]) => {
        const differingKeys = Object.keys(stockItem)
          .filter((key: string) => key !== 'originalValue')
          .filter((key: string) => key !== 'isEdited')
          .filter((key: string) => key !== 'error')
          .filter((key: string) => stockItem[key] !== stockItem.originalValue[key]?.value);
        const anyValueDiffers = differingKeys.length > 0;

        if (anyValueDiffers) {
          return [StockManagerActions.setEditedItemAdd({
            stockItem: {...stockItem, isEdited: true},
            store: currentSelectedStore,
          })];
        } else if (editedItems?.[stockItem.code]) {
          return [StockManagerActions.setEditedItemRemove({
            stockItem: {...stockItem, isEdited: false},
            store: currentSelectedStore,
          })];
        } else {
          return [];
        }
      }),
    ),
  );

  // this happens on multiple actions
  updateStockItemsWithEdited$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StockManagerActions.getAllStockItemsSuccess,
        StockManagerActions.getPaginationResultsForStockItemsSuccess,
        StockManagerActions.getSearchResultsForStockItemsSuccess,
        StockManagerActions.setNextPageForSharedGridAtStoreId,
        StockManagerActions.setEditedItemsToStockPage,
      ),
      withLatestFrom(
        this.store.pipe(select(selectEditedItems)),
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectAllStockItemsForCurrentSelectedStore)),
      ),
      mergeMap(([_, editedItems, queryData, stockItems]) => {
        const {store} = queryData;
        let updatedStockItems: StockItem[] = [];

        if (editedItems && Object.keys(editedItems).length > 0) {
          const editedItemCodes = Object.keys(editedItems);
          updatedStockItems = stockItems.map((stockItem: StockItem) => {
            if (editedItemCodes.includes(stockItem.code)) {
              const editedItem = editedItems[stockItem.code];
              return {
                ...editedItem,
              };
            }
            return stockItem;
          });
        } else {
          updatedStockItems = stockItems;
        }

        return of(
          StockManagerActions.updateStockItemsWithEditedSuccess({
            stockItems: updatedStockItems,
            store,
            editedItems: editedItems ? Object.values(editedItems) : [],
          }),
        );
      }),
    ),
  );

  // =========================================
  // Item Disabling Rules
  // =========================================

  //'[Stock Manager][Item Disabling] Set Item to be Disabled',
  setItemDisabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setItemDisabledStatus),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectItemDisablingRules)),
      ),
      switchMap(([action, queryData, disabledRules]) => {
        const {store} = queryData;
        const actions: Action[] = [];
        const stockItem = {...action.stockItem};
        StockFunctions.enableItem(stockItem, disabledRules, action.enable);
        return [StockManagerActions.setItemDisabledWithStoreID({stockItem, store})];
      }),
    ),
  );

  // ====================================================================================================
  // Set Price And NGP For Stock Manager
  // ====================================================================================================

  //'[Stock Manager][Price] Set The Price, NGP for Stock Manager',
  updatePriceGpForNgpReport$ = createEffect(() => this.actions$.pipe(
    ofType(StockManagerActions.setPriceGpForStockManager),
    withLatestFrom(
      this.store.pipe(select(selectSelectedUserStores))),
    switchMap(([action, currentSelectedStore]) => {
      const storeId = currentSelectedStore.storeId;
      return [
        StockManagerActions.setPriceGpForStockMangerWithStoreID({
          stockItem: action.stockItem,
          field: action.field,
          storeId: storeId,
        }),
        StockManagerActions.setEditedItem({stockItem: action.stockItem}),
      ];
    }),
  ));
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Everything for Preview And Submit
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  //'[Stock Manager][Preview Columns] Get Stock Manager Preview Columns'
  getStockManagerPreviewColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getStockManagerPreviewColumns),
      switchMap(() => {
        return from(this.firebaseService.getUserDocument('stock_edits_preview')).pipe(
          map((columnData: {
            [key: number]: string
          }) => StockManagerActions.setStockManagerPreviewColumnsSuccess({columnData: columnData ? columnData : {}})),
          catchError((error: IError) => of(StockManagerActions.setStockManagerPreviewColumnsFailure({error}))),
        );
      }),
    ),
  );

  //'[Stock Manager][Update Firebase] Set Stock Updates On Firebase For Stock Manager'
  setStockUpdatesForStockManger$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setStockUpdatesForStockManger),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      switchMap(([{ itemsToUpdate }, store]) => {
        if (Object.keys(itemsToUpdate).length) {
          return from(this.firebaseService.stockUpdate(store.storeId, itemsToUpdate)).pipe(
            map(() => StockManagerActions.removeEditedItemsAfterStockUpdate({ store })),
            catchError((error: IError) => {
              return of(StockManagerActions.stockUpdateFailure({ error }));
            })
          );
        } else {
          return [];
        }
      })
    )
  );

  removeEditedItemsAfterStockUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.removeEditedItemsAfterStockUpdate),
      map(() => StockManagerActions.getSearchResultsForStockItems({ idField: '' })) // Dispatch action with empty array
    )
  );


  //'[Stock Manager][Edited Items] Get Edited Items from Typesense'
  getEditedItemsFromTypesense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getEditedItemsFromTypesense),
      withLatestFrom(
        this.store.pipe(select(selectTypesenseQueryData)),
        this.store.pipe(select(selectEditedItems)),
        this.store.pipe(select(selectPriceBandingForUserSelectedStore)),
        this.store.pipe(select(selectSharedGridVatRateBySelectedStore)),
        this.store.pipe(select(selectEnablePriceBandingForStockManager)),
      ),
      mergeMap(([_, queryData, editedItems, priceBanding, vatRates, isPriceBanding]) => {
        const {store, paginationData: {searchedValue}, searchableFields} = queryData;
        const fetchAllPages = (currentPage = 1, combinedHits: StockItem[] = []): Observable<{
          hits: StockItem[];
          facetCounts: IFacetCounts | null
        }> => {
          return this.typeSenseService.searchEditedStockItems(searchedValue, currentPage, store, searchableFields, editedItems).pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const newHits = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const allHits = [...combinedHits, ...newHits];
              const facetCounts = createFacetCountsMap(stockItemsRaw.facet_counts || []);

              if (allHits.length < stockItemsRaw.found) {
                return fetchAllPages(currentPage + 1, allHits);
              } else {
                return of({hits: allHits, facetCounts});
              }
            }),
          );
        };

        return fetchAllPages().pipe(
          mergeMap(({hits: allStockItems, facetCounts}) => {
            const mappedEditedStockItems = allStockItems.map((item: StockItem) => ({
              ...item,
              storeId: store.storeId,
              store: store.name,
              isEdited: true,
              isError: false,
            }));
            const discrepancies: {
              code: string;
              field: string;
              original: string | number | boolean | Date;
              current: string | number | boolean | Date
            }[] = [];

            mappedEditedStockItems.forEach(item => {
              const editedItem = editedItems[item.code];
              if (editedItem) {
                Object.keys(item).forEach(key => {
                  if (key !== 'originalValue' && key !== 'isEdited' && item[key] !== editedItem.originalValue[key]?.value && sItemKeyToInt[key]) {
                    discrepancies.push({
                      code: item.code,
                      field: sItemKeyToInt[key],
                      original: editedItem.originalValue[key]?.value,
                      current: item[key],
                    });
                  }
                });
              }
            });

            if (discrepancies.length > 0) {
              const discrepancyMessages = discrepancies.map(d =>
                `Item Code: ${d.code} | Field: ${sItemIntToKey[d.field]} | Local: ${d.original} | Remote: ${d.current}`,
              ).join('\n');
              alert(`Discrepancies found in edited items:\n${discrepancyMessages}`);
            }

            return [
              StockManagerActions.getEditedItemsFromTypesenseSuccess({
                editedItemsFromTypesense: mappedEditedStockItems,
                store,
                facetCounts: facetCounts || {},
                priceBandedItems: isPriceBanding ? applyPriceBanding(
                  Object.values(editedItems),
                  priceBanding,
                  PRICE_BANDING_COLUMNS_FOR_NGP_REPORTS,
                  store,
                  vatRates
                ) : Object.values(editedItems)
              }),
            ];
          }),
          catchError((error: IError) =>
            of(StockManagerActions.getEditedItemsFromTypesenseFailure({error, store})),
          ),
        );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  // Pagination and Search functions
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>

  //'[Stock Manager][Pagination] Set Page Size For Shared Grid',
  setPageSizeForSharedGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setPageSizeForSharedGrid),
      withLatestFrom(
        this.store.pipe(select(selectSelectedUserStores)),
        this.store.pipe(select(selectPaginationDataForUserSelectedStore)),
      ),
      map(([{pageSize}, store, {currentPage}]) => {
        // Dispatch success action with pageSize and store ID
        return StockManagerActions.setPageSizeForSharedGridAtStoreId({
          pageSize: pageSize,
          store: store,
          pageNumber: currentPage,
        });
      }),
    ),
  );

  //'[Stock Manager][Pagination] Set Next Page For Shared Grid',
  setNextPageForSharedGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.setNextPageForSharedGrid),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores)),
        this.store.pipe(select(selectPaginationDataForUserSelectedStore))),
      mergeMap(([_, store, paginationData]) => {
        const newPaginationData = getDeepCopyOfObject(paginationData);
        newPaginationData.currentPage++;
        return of(StockManagerActions.setNextPageForSharedGridAtStoreId({paginationData: newPaginationData, store}));
      }),
    ));

  //'[Stock Manager][Pagination] Get Pagination Results For Stock Item [Grid]',
  getPaginationResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getPaginationResultsForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{pageNumber}, queryData]) => {
        const {
          store,
          paginationData: {pageSize, searchedValue},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            pageNumber,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            mergeMap((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: pageNumber,
                searchedValue,
              };
              const facetCounts: IFacetCounts = createFacetCountsMap(stockItemsRaw.facet_counts);
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });

              return [
                StockManagerActions.getPaginationResultsForStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                  facetCounts,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getPaginationResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  //'[Stock Manager][Pagination] Get Next Page For Stock Items',
  getNextPageForStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getNextPageForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      filter(([_, queryData]) => !!queryData.store && !!queryData.paginationData && !!queryData.searchableFields),
      mergeMap(([_, queryData]) => {
        const {
          store,
          paginationData: {currentPage, pageSize, searchedValue, totalItems},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        const nextPageNumber = currentPage + 1 < totalItems / pageSize ? currentPage + 1 : 1;

        return this.typeSenseService
          .searchStockItems(
            searchedValue,
            nextPageNumber,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((nextPageItems: TypeSenseSearchResponse<StockItem>) => {
              const mappedNextPageItems = mapTypeSenseObjectToGeneric<StockItem>(nextPageItems).map((item) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
              }));
              mappedNextPageItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              const nextPaginationData: IPaginationData = {
                totalItems: nextPageItems.found,
                pageSize,
                currentPage: currentPage,
                searchedValue,
              };
              return StockManagerActions.getNextPageForStockItemsSuccess({
                stockItems: mappedNextPageItems,
                store,
                nextPaginationData,
              });
            }),
            catchError((error: IError) =>
              of(StockManagerActions.getNextPageForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );

  // =======================================================================
  // Search Results
  // =======================================================================

  //'[Stock Manager][Search Results] Get Search Results For Stock Items',
  getSearchResultsForStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StockManagerActions.getSearchResultsForStockItems),
      withLatestFrom(this.store.pipe(select(selectTypesenseQueryData))),
      mergeMap(([{idField}, queryData]) => {
        const {
          store,
          paginationData: {pageSize},
          searchableFields,
          sortType,
          filters,
          advancedFilterGroup,
        } = queryData;

        return this.typeSenseService
          .searchStockItems(
            idField,
            1,
            pageSize,
            store,
            searchableFields,
            filters,
            sortType,
            advancedFilterGroup,
          )
          .pipe(
            map((stockItemsRaw: TypeSenseSearchResponse<StockItem>) => {
              const paginationData: IPaginationData = {
                totalItems: stockItemsRaw.found,
                pageSize,
                currentPage: 1,
                searchedValue: idField,
              };
              const mappedStockItems = mapTypeSenseObjectToGeneric<StockItem>(stockItemsRaw);
              const stockItems = mappedStockItems.map((item: StockItem) => ({
                ...item,
                storeId: store.storeId,
                store: store.name,
                isEdited: false,
                isError: false,
              }));
              stockItems.forEach((item: StockItem) => {
                const stockItem = calculateGPFromPrice(item, store, 2);
                item.disabled = false;
                item.nominalGP = +stockItem.nominalGP;
              });
              return [
                StockManagerActions.getSearchResultsForStockItemsSuccess({
                  stockItems,
                  store,
                  paginationData,
                }),
                StockManagerActions.getNextPageForStockItems(),
              ];
            }),
            mergeMap((actions) => from(actions)),
            catchError((error: IError) =>
              of(StockManagerActions.getSearchResultsForStockItemsFailure({error, store})),
            ),
          );
      }),
    ),
  );
  // <><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><><>


  constructor(
    private actions$: Actions,
    private firebaseService: FirebaseService,
    private typeSenseService: TypeSenseService,
    private readonly store: Store,
  ) {
  }
}
