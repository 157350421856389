import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SharedActions from '../store-shared/shared.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {selectSelectedUserStores} from '../../../features-as-modules/feature-core/store/core.selectors';
import {select, Store} from '@ngrx/store';
import {
  CollectionSharedSuppliersService
} from '../../../shared/shared-services/firebase/collection-shared-suppliers.service';
import {of} from 'rxjs';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';

@Injectable()
export class SharedEffects {

  // ====================================================================================================
  // Suppliers
  // ====================================================================================================

  // '[Core Shared][Suppliers] Get Store Suppliers By User Selected Store'
  getStoreSuppliersByUserSelectedStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SharedActions.getStoreSuppliersByUserSelectedStore),
      withLatestFrom(this.store.pipe(select(selectSelectedUserStores))),
      map(([_, selectedUserStores]) => {
        return SharedActions.getStoreSuppliersByStore({store: selectedUserStores});
      })
    )
  );

  // '[Core Shared][Suppliers] Get Store Suppliers By Store',
  getStoreSuppliersByStore$ = createEffect(() => this.actions$.pipe(
    ofType(SharedActions.getStoreSuppliersByStore),
    mergeMap(({store}) =>
      this.collectionSharedSuppliersService.getSuppliersByStore(store)
        .pipe(
          map((suppliers: { [supplierId: string]: ISupplier }) =>
            SharedActions.getStoreSuppliersByStoreSuccess({suppliers, store})),
          catchError((error) => of(SharedActions.getStoreSuppliersByStoreFailure({error})))
        )
    )
  ));

  constructor(
    private actions$: Actions,
    private collectionSharedSuppliersService: CollectionSharedSuppliersService,
    private readonly store: Store
  ) {
  }
}
