import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {IDepartment} from '../../../../shared/shared-models/stock/departments';
import {
  onHoldDecode,
  onHoldStockItemsEncode,
  onHoldTypes,
  StockItem,
} from '../../../../shared-utilities/models-old/datastructures';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-cell-editor-multi-select',
  templateUrl: './cell-editor-multi-select.component.html',
  styleUrls: ['./cell-editor-multi-select.component.scss'],
})
export class CellEditorMultiSelectComponent implements ICellEditorAngularComp {
  @HostListener('window:resize')
  onWindowResize(): void {
    if (this.showOptions) {
      this.adjustPosition();
    }
  }
  @ViewChild('input', {static: true}) input: ElementRef;
  @ViewChild('selectElement', {static: true}) selectElement: ElementRef<HTMLSelectElement>;

  onHoldTypes = onHoldTypes;

  value: number;
  params: ICellEditorParams;
  width: number;
  colDefField: string;
  icons: typeof Icons = Icons;
  departments: IDepartment[] = [];
  displayAbove: boolean = false;

  selectedValues: string[] = [];
  showOptions = false;
  protected readonly Object = Object;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(params: ICellEditorParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField] as number;
    this.width = this.params.colDef.width;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.selectedValues = onHoldDecode(+this.value, false, false);
  }

  getValue(): string {
    this.params.data.isError = onHoldDecode(+this.value) === null;
    this.params.data.isEdited = this.params.data[this.colDefField] === this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.updateErrorAndForceRefreshForOnHoldCode(this.params, +this.value);
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    GridUtils.updateSingleItem(this.params.data as NGPReport | StockItem, this.colDefField, this.value, this.store, 'stock-manager');
    return this.value.toString();
  }

  refresh(params: ICellEditorParams<NGPReport | StockItem, string | number>): boolean {
    GridUtils.updateIsEditedAndForceRefresh(this.params);
    return false;
  }

  afterGuiAttached(): void {
    this.input.nativeElement.focus();
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.colDefField, this.store, 'stock-manager')
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  toggleDropdown(): void {
    this.showOptions = !this.showOptions;
    if (this.showOptions) {
      this.adjustPosition();
    }
  }

  trackByFunction(index: number): number {
    return index;
  }

  adjustPosition(): void  {
    const inputRect = this.input.nativeElement.getBoundingClientRect();
    const optionsHeight = 400;
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    this.displayAbove = spaceBelow < optionsHeight && spaceAbove > optionsHeight;
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  toggleSelection(value: string): void {
    if (this.selectedValues.includes(this.onHoldTypes[value] as unknown as string)) {
      this.selectedValues = this.selectedValues.filter((v: string) => v !== this.onHoldTypes[value] as unknown as string);
      this.value = this.selectedValues.length > 0 ? onHoldStockItemsEncode(this.selectedValues) : 0;

    } else {
      this.selectedValues.push(this.onHoldTypes[value] as unknown as string);
      this.value = this.selectedValues.length > 0 ? onHoldStockItemsEncode(this.selectedValues) : 0;
    }
    this.params.data[this.colDefField] = this.value;
    this.params.value = this.value;
  }
}
