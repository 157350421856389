import {ApplicationSettingsComponent} from './application-settings/application-settings.component';
import {AoReattemptModalComponent} from './ao-reattempt-modal/ao-reattempt-modal.component';
import {AutomationSettingsEmailComponent} from './automation-settings-email/automation-settings-email.component';
import {BasicInputComponent} from './basic-input/basic-input.component';
import {BasicMultipleInputComponent} from './basic-multiple-input/basic-multiple-input.component';
import {DataFreshnessExplanationComponent} from './data-freshness-explanation/data-freshness-explanation.component';
import {DataFreshnessIndicatorComponent} from './data-freshness-indicator/data-freshness-indicator.component';
import {DataFreshnessPopupComponent} from './data-freshness-popup/data-freshness-popup.component';
import {DateRangeSelectorComponent} from './date-range-selector/date-range-selector.component';
import {DefaultBodyComponent} from './messages-modal/messages-modal.page';
import {DisableSupplierSettingsComponent} from './disable-supplier-dettings/disable-supplier-settings.component';
import {EditorsConfigComponent} from './editors-config/editors-config.component';
import {FacetListComponent} from './facet-list/facet-list.component';
import {FailedOrdersAlertComponent} from './failed-orders-alert/failed-orders-alert.component';
import {InactivityModalComponent} from './inactivity-modal/inactivity-modal.component';
import {MenuPopupComponent} from './menu-popup/menu-popup.component';
import {ModalHeaderComponent} from './modal-header/modal-header.component';
import {MsgAoResponseComponent} from './msg-ao-response/msg-ao-response.component';
import {MsgApiResponseComponent, PopoverComponent} from './msg-api-response/msg-api-response.component';
import {
  MsgUserSignupNotificationComponent
} from './msg-user-signup-notification/msg-user-signup-notification.component';
import {MultipleErrorPopoverComponent} from './multiple-error-popover/multiple-error-popover.component';
import {NavigableModalComponent} from './navigable-modal/navigable-modal.component';
import {PageHeaderComponent} from './page-header-old/page-header.component';
import {PriceBandingComponent} from './price-banding/price-banding.component';
import {PriceCellEditorComponent} from './price-cell-editor/price-cell-editor.component';
import {PriceThresholdComponent} from './price-threshold/price-threshold.component';
import {ProgressComponent} from './progress/progress.component';
import {SaveUserSettingsPage} from '../modules-old/save-user-settings/save-user-settings.page';
import {SelectPopoverComponent} from './select-popover/select-popover.component';
import {SharedStoreTabsComponent} from './shared-store-tabs/shared-store-tabs.component';
import {SimplePaginationComponent} from './simple-pagination/simple-pagination.component';
import {StockFiltersComponent} from './stock-filters/stock-filters.component';
import {StockHistoryLogComponent} from './stock-history-log/stock-history-log.component';
import {StockSearchComponent} from './stock-search/stock-search.component';
import {StockUpdatesViewerComponent} from './stock-updates-viewer/stock-updates-viewer.component';
import {StockValChangeFlagsComponent} from './stock-val-change-flags/stock-val-change-flags.component';
import {StoreConfigComponent} from './store-config/store-config.component';
import {StoreInfoComponent} from './store-info/store-info.component';
import {SuccessfulEventFabComponent} from './successful-event-fab/successful-event-fab.component';
import {UnlinkedDepartmentsModalComponent} from './unlinked-departments-modal/unlinked-departments-modal.component';
import {UserProfileConfigComponent} from './user-profile-config/user-profile-config.component';
import {
  DelItemSettingsComponent
} from '../../../features-as-modules/feature-stock-updates/components/del-item-settings/del-item-settings.component';
import {
  SharedGridTableNavigationComponent
} from '../../shared-grid/components/shared-grid-table-navigation/shared-grid-table-navigation.component';

export const AO_REATTEMPT_COMPONENT = AoReattemptModalComponent;
export const APPLICATION_SETTINGS = ApplicationSettingsComponent;
export const AUTOMATION_SETTINGS_EMAIL = AutomationSettingsEmailComponent;
export const BASIC_INPUT = BasicInputComponent;
export const BASIC_MULTIPLE_INPUT = BasicMultipleInputComponent;
export const DATA_FRESHNESS_EXPLANATION = DataFreshnessExplanationComponent;
export const DATA_FRESHNESS_INDICATOR = DataFreshnessIndicatorComponent;
export const DATA_FRESHNESS_POPUP = DataFreshnessPopupComponent;
export const DATE_RANGE_SELECTOR = DateRangeSelectorComponent;
export const DEFAULT_BODY_COMPONENT = DefaultBodyComponent;
export const DEL_ITEM_SETTINGS = DelItemSettingsComponent;
export const DISABLE_SUPPLIER_SETTINGS = DisableSupplierSettingsComponent;
export const EDITORS_CONFIG = EditorsConfigComponent;
export const FACET_LIST = FacetListComponent;
export const FAILED_ORDERS_ALERT_COMPONENT = FailedOrdersAlertComponent;
export const INACTIVITY_MODAL = InactivityModalComponent;
export const MENU_POPUP_COMPONENT = MenuPopupComponent;
export const MODAL_HEADER = ModalHeaderComponent;
export const MSG_AO_RESPONSE_COMPONENT = MsgAoResponseComponent;
export const MSG_API_RESPONSE_COMPONENT = MsgApiResponseComponent;
export const MSG_USER_SIGNUP_NOTIFICATION_COMPONENT = MsgUserSignupNotificationComponent;
export const MULTIPLE_ERROR_POPOVER = MultipleErrorPopoverComponent;
export const NAVIGABLE_MODAL = NavigableModalComponent;
export const PAGE_HEADER_OLD = PageHeaderComponent;
export const POPOVER_COMPONENT = PopoverComponent;
export const PRICE_BANDING = PriceBandingComponent;
export const PRICE_CELL_EDITOR = PriceCellEditorComponent;
export const PRICE_THRESH_HOLD = PriceThresholdComponent;
export const PROGRESS_COMPONENT = ProgressComponent;
export const SAVE_USER_SETTINGS = SaveUserSettingsPage;
export const SELECT_POPOVER = SelectPopoverComponent;
export const SHARED_STORE_TABS = SharedStoreTabsComponent;
export const SIMPLE_PAGINATION = SimplePaginationComponent;
export const STOCK_FILTERS = StockFiltersComponent;
export const STOCK_HISTORY_LOG_COMPONENT = StockHistoryLogComponent;
export const STOCK_SEARCH = StockSearchComponent;
export const STOCK_UPDATES_VIEWER_COMPONENT = StockUpdatesViewerComponent;
export const STOCK_VAL_CHANGE_FLAGS = StockValChangeFlagsComponent;
export const STORE_CONFIG = StoreConfigComponent;
export const STORE_INFO = StoreInfoComponent;
export const SUCCESSFUL_EVENT_FAB = SuccessfulEventFabComponent;
export const UNLINKED_DEPARTMENTS_MODAL = UnlinkedDepartmentsModalComponent;
export const USER_PROFILE_CONFIG = UserProfileConfigComponent;
export const SHARED_GRID_TABLE_NAVIGATION = SharedGridTableNavigationComponent;

export const SHARED_MODULE_COMPONENTS = [
  AO_REATTEMPT_COMPONENT,
  APPLICATION_SETTINGS,
  AUTOMATION_SETTINGS_EMAIL,
  BASIC_INPUT,
  BASIC_MULTIPLE_INPUT,
  DATA_FRESHNESS_EXPLANATION,
  DATA_FRESHNESS_INDICATOR,
  DATA_FRESHNESS_POPUP,
  DATE_RANGE_SELECTOR,
  DEFAULT_BODY_COMPONENT,
  DEL_ITEM_SETTINGS,
  DISABLE_SUPPLIER_SETTINGS,
  EDITORS_CONFIG,
  FACET_LIST,
  FAILED_ORDERS_ALERT_COMPONENT,
  INACTIVITY_MODAL,
  MENU_POPUP_COMPONENT,
  MODAL_HEADER,
  MSG_AO_RESPONSE_COMPONENT,
  MSG_API_RESPONSE_COMPONENT,
  MSG_USER_SIGNUP_NOTIFICATION_COMPONENT,
  MULTIPLE_ERROR_POPOVER,
  NAVIGABLE_MODAL,
  PAGE_HEADER_OLD,
  POPOVER_COMPONENT,
  PRICE_BANDING,
  PRICE_CELL_EDITOR,
  PRICE_THRESH_HOLD,
  PROGRESS_COMPONENT,
  SAVE_USER_SETTINGS,
  SELECT_POPOVER,
  SHARED_STORE_TABS,
  SIMPLE_PAGINATION,
  STOCK_FILTERS,
  STOCK_HISTORY_LOG_COMPONENT,
  STOCK_SEARCH,
  STOCK_UPDATES_VIEWER_COMPONENT,
  STOCK_VAL_CHANGE_FLAGS,
  STORE_CONFIG,
  STORE_INFO,
  SUCCESSFUL_EVENT_FAB,
  UNLINKED_DEPARTMENTS_MODAL,
  USER_PROFILE_CONFIG,
  SHARED_GRID_TABLE_NAVIGATION
];
