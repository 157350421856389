import {SharedLoadingIndicatorComponent} from './shared-loading-indicator/shared-loading-indicator.component';
import {SharedModalBasicComponent} from './shared-modal-basic/shared-modal-basic.component';
import {
  SharedModalFiltersAdvancedComponent
} from './shared-modal-filters-advanced/shared-modal-filters-advanced.component';
import {
  SharedModalFiltersAdvancedGroupComponent
} from './shared-modal-filters-advanced-group/shared-modal-filters-advanced-group.component';
import {
  SharedModalFiltersAdvancedGroupItemComponent
} from './shared-modal-filters-advanced-group-item/shared-modal-filters-advanced-group-item.component';
import {
  SharedModalFiltersAdvancedContainerComponent
} from './shared-modal-filters-advanced-container/shared-modal-filters-advanced-container.component';

export const SHARED_LOADING_INDICATOR = SharedLoadingIndicatorComponent;
export const SHARED_MODAL_BASIC = SharedModalBasicComponent;
export const SHARED_MODAL_FILTERS_ADVANCED = SharedModalFiltersAdvancedComponent;
export const SHARED_MODAL_FILTERS_ADVANCED_CONTAINER = SharedModalFiltersAdvancedContainerComponent
export const SHARED_MODAL_FILTERS_ADVANCED_GROUP = SharedModalFiltersAdvancedGroupComponent;
export const SHARED_MODAL_FILTERS_ADVANCED_GROUP_ITEM = SharedModalFiltersAdvancedGroupItemComponent;

export const SHARED_COMPONENTS = [
  SHARED_LOADING_INDICATOR,
  SHARED_MODAL_BASIC,
  SHARED_MODAL_FILTERS_ADVANCED,
  SHARED_MODAL_FILTERS_ADVANCED_CONTAINER,
  SHARED_MODAL_FILTERS_ADVANCED_GROUP,
  SHARED_MODAL_FILTERS_ADVANCED_GROUP_ITEM
];
