import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {NumberUtils} from '../../../../shared-utilities/utils-old/shared-utils-old/number-utils';
import {DataAdditionalNumbers} from '../../../../shared-utilities/models-old/ngp-report-grid/data-additional-numbers';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {Store} from '@ngrx/store';
import {
  setEditedItem,
  setEditedItemsToStockPage,
  setPriceGpForStockManager,
} from '../../../../features/stock-manager/store/stock-manager.actions';
import {Observable} from 'rxjs';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';

/**
 * CellRendererCurrencyComponent renders custom HTML within an AG Grid Cell
 * The data manipulates numbers and displays a string formatted according to
 * additional data provided in the column definitions.
 */
@Component({
  selector: 'app-cell-renderer-currency',
  templateUrl: './cell-renderer-currency.component.html',
  styleUrls: ['./cell-renderer-currency.component.scss'],
})
export class CellRendererCurrencyComponent implements ICellRendererAngularComp {

  /**
   * Todo: Add custom formatting for values from a setting
   * In example 4500.00 -> 4 500 | 4,500.00
   * Look at Date FNS - we can add custom formatting to all values
   */

  price: number | string = '';
  params: ICellRendererParams &
    { dataAdditional: DataAdditionalNumbers };

  currentPage$: Observable<string>;
  currentPage: string;

  constructor(
    private readonly store: Store,
  ) {
  }

  agInit(
    params: ICellRendererParams &
      { dataAdditional: DataAdditionalNumbers },
  ): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.params.colDef.field] = {...params.data.originalValue[this.params.colDef.field]};
    this.price = +this.formatCurrency(+params?.value ?? '', 2);
    this.currentPage$ = this.store.select(selectCurrentPage);
    this.currentPage$.subscribe((page: string) => {
      this.currentPage = page;
    });
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  onUndoButtonClick(): void {
    this.price = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.value = this.params.data.originalValue[this.params.colDef.field].value;
    this.params.data[this.params.colDef.field] = this.params.data.originalValue[this.params.colDef.field].value;
    if (this.currentPage === 'stock-manager') {
      if (this.params.colDef.field === 'sellPriIncl1' || this.params.colDef.field === 'nominalGP') {
        const isAnyEdited = Object.keys(this.params.data.originalValue).some((key: string) => {
          if (key !== 'isEdited' && key !== 'isSelected' && key !== 'error' && key !== 'newlyAdded' && key !== 'isError' && key !== 'nominalGP' && key !== 'sellPriIncl1') {
            const originalValue = this.params.data.originalValue[key].value;
            const currentValue = this.params.data[key];
            return originalValue !== currentValue;
          }
          return false;
        });
        this.params.data.isEdited = isAnyEdited;
        this.store.dispatch(setPriceGpForStockManager({stockItem: this.params.data, field: this.params.colDef.field}));
      } else {
        this.store.dispatch(setEditedItem({stockItem: this.params.data as StockItem}));
        this.store.dispatch(setEditedItemsToStockPage());
        GridUtils.updateIsEditedAndForceRefresh(this.params);
      }
    } else {
      GridUtils.removeIsEditingFlagFromNgpItem(this.params.data, this.params.colDef.field, this.store);
      GridUtils.updateIsEditedAndForceRefresh(this.params);
    }
  }

  /**
   * Formats the value to display either a number value formatted according to the user settings.
   * If no value is provided it will format the value to a string.
   *
   * @param value The value that needs to be formatted.
   * @param decimalPlaces The number of decimals to be displayed after the number.
   * @returns If no value is provided - returns an empty string.
   * @returns If a value is provided - returns a formatted value according to the user settings.
   */
  private formatCurrency(value: number | string, decimalPlaces: number): number | string {
    if (typeof value === 'string') {
      return '';
    }
    return NumberUtils.formatNumberToDecimals(value, decimalPlaces);
  }

}
