// ===================================================================
// OPERATIONAL PATHS
// ===================================================================

export const path_operational_stores_data_auto_ordering_auto_orders_GET = (storeId: string): string => {
  return `operational/stores_data/${storeId}/data/auto_ordering/auto_orders/orders`
};

// ===================================================================
// SHARED PATHS
// ===================================================================

// Singular Documents =============================================

export const path_shared_store_data_singular_document_advanced_filter_group_DELETE = (storeId: string, filterId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/advanced_filters/filters/${filterId}`
};

export const path_shared_store_data_singular_document_advanced_filter_group_GET = (storeId: string, filterId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/advanced_filters/filters/${filterId}`
};

export const path_shared_store_data_singular_document_advanced_filter_groups_GET = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/advanced_filters/filters`
};

export const path_shared_store_data_singular_document_advanced_filter_POST = (storeId: string, filterId: string): string => {
  return `shared/stores_data/${storeId}/data/singular_documents/advanced_filters/filters/${filterId}`
};

// Stock ==========================================================

export const path_shared_store_data_stock_GET = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/stock`
};

// Suppliers ======================================================
export const path_shared_store_data_suppliers_GET = (storeId: string): string => {
  return `shared/stores_data/${storeId}/data/suppliers`
};
