import {SettingsStockManagerComponent} from './settings-stock-manager/settings-stock-manager.component';
import {SettingsNgpReportComponent} from './settings-ngp-report/settings-ngp-report.component';
import {
  SettingsNgpReportsContainerComponent
} from './settings-ngp-reports-container/settings-ngp-reports-container.component';
import {
  SettingsStockManagerContainerComponent
} from './settings-stock-manager-container/settings-stock-manager-container.component';
import {SettingsGeneralGridComponent} from './settings-general-grid/settings-general-grid.component';
import {
  SettingsGeneralGridContainerComponent
} from './settings-general-grid-container/settings-general-grid-container.component';

export const SETTINGS_STOCK_MANAGER = SettingsStockManagerComponent;
export const SETTINGS_STOCK_MANAGER_CONTAINER = SettingsStockManagerContainerComponent;
export const SETTINGS_NGP_REPORT = SettingsNgpReportComponent;
export const SETTINGS_NGP_REPORT_CONTAINER = SettingsNgpReportsContainerComponent;
export const SETTINGS_GENERAL_GRID_CONTAINER = SettingsGeneralGridContainerComponent;
export const SETTINGS_GENERAL_GRID = SettingsGeneralGridComponent;

export const STORE_SETTINGS_COMPONENTS = [
  SETTINGS_NGP_REPORT,
  SETTINGS_STOCK_MANAGER,
  SETTINGS_STOCK_MANAGER_CONTAINER,
  SETTINGS_NGP_REPORT_CONTAINER,
  SETTINGS_GENERAL_GRID,
  SETTINGS_GENERAL_GRID_CONTAINER,
];
