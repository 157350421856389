import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {IInitialState} from '../../../shared-utilities/models-old/initial-state/initial-state';
import {IError} from '../../../shared-utilities/models-old/error/error';
import * as SharedActions from '../store-shared/shared.actions';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';

export interface SharedState extends IInitialState {
  errors: IError[]
  // Suppliers ------------------------------
  isSuppliersLoading: boolean;
  suppliers: {
    [storeId: string]: { [supplierId: string]: ISupplier }
  }
}

export const initialSharedState: SharedState = {
  // Initial --------------------------------
  errors: [],
  // Suppliers ------------------------------
  isSuppliersLoading: false,
  suppliers: {}
};

const createSharedReducer: ActionReducer<SharedState> = createReducer(
  initialSharedState,
  // ====================================================================================================
  // Suppliers
  // ====================================================================================================
  on(SharedActions.getStoreSuppliersByUserSelectedStore, (state: SharedState) => ({
    ...state,
    isSuppliersLoading: true
  })),
  on(SharedActions.getStoreSuppliersByStore, (state: SharedState) => ({
    ...state,
    isSuppliersLoading: true
  })),
  on(SharedActions.getStoreSuppliersByStoreSuccess, (state: SharedState, {suppliers, store}) => ({
    ...state,
    isSuppliersLoading: false,
    suppliers: {
      ...state.suppliers,
      [store.storeId]: suppliers
    }
  })),
  on(SharedActions.getStoreSuppliersByStoreFailure, (state: SharedState, {error}) => ({
    ...state,
    isSuppliersLoading: false,
    error: [...state.errors, error]
  })),
);

export const sharedReducer = (state: SharedState, action: Action): SharedState => createSharedReducer(state, action);
