import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ICellEditorParams} from 'ag-grid-community';
import {Icons} from '../../../shared-icons/icons';
import {GridUtils} from '../../../../shared-utilities/utils-old/grid-utils-old/grid-utils';
import {NumberUtils} from '../../../../shared-utilities/utils-old/shared-utils-old/number-utils';
import {Store} from '@ngrx/store';
import {updateSingleVisibleField} from '../../../../features-as-modules/feature-ngp-report/store/ngp-report.actions';
import {selectSharedGridVisibleFieldsByCurrentPage} from '../../store/shared-grid.selectors';
import {Observable} from 'rxjs';
import {setEditedItem} from '../../../../features/stock-manager/store/stock-manager.actions';
import {selectCurrentPage} from '../../../../features-as-modules/feature-core/store/core.selectors';
import {NGPReport} from '../../../../shared-utilities/models-old/ngp-reports/ngp-report';
import {StockItem} from '../../../../shared-utilities/models-old/datastructures';

@Component({
  selector: 'app-cell-editor-general',
  templateUrl: './cell-editor-general.component.html',
  styleUrls: ['./cell-editor-general.component.scss'],
})
export class CellEditorGeneralComponent implements ICellEditorAngularComp, OnInit {

  @ViewChild('input') input: ElementRef;

  value: string | number;
  params: ICellEditorParams;
  width: string;
  colDefField: string;
  colDefFieldType: string;
  currentVisibleFields: { [key: string]: boolean } = {};

  visibleFields$: Observable<{ [key: string]: boolean }>;
  currentPage$: Observable<string>
  currentPage: string;


  readonly icons: typeof Icons = Icons;

  constructor(private readonly store: Store) {
  }

  agInit(params: ICellEditorParams): void {
    this.params = {...params};
    this.params.data = {...params.data};
    this.params.colDef = {...params.colDef};
    this.colDefField = params.colDef.field;
    this.value = this.params.data[this.colDefField];
    this.width = `${this.params.colDef.width}px`;
    this.params.data.originalValue = {...params.data.originalValue};
    this.params.data.originalValue[this.colDefField] = {...params.data.originalValue[this.colDefField]};
    this.visibleFields$ = this.store.select(selectSharedGridVisibleFieldsByCurrentPage);
    this.visibleFields$.subscribe((visibleFields: { [p: string]: boolean }) => {
      this.currentVisibleFields = {...visibleFields};
    });
    this.currentPage$ = this.store.select(selectCurrentPage)
    this.currentPage$.subscribe((page: string ) => {
      this.currentPage = page;
    })
  }

  ngOnInit(): void {
    this.width = `${this.params.colDef.width}px`;
    this.colDefFieldType = typeof this.params.value;
  }

  selectText(): void {
    setTimeout((): void => {
      this.input.nativeElement.focus();
      this.input.nativeElement.setSelectionRange(0, this.value.toString().length);
      this.input.nativeElement.select();
    }, 1);
  }

  onUndoChangesClick(): void {
    this.value = this.params.data.originalValue[this.colDefField].value;
    this.params.data[this.colDefField] = this.value;
    GridUtils.removeIsEditingFlagAndDispatch(this.params.data as NGPReport | StockItem, this.colDefField, this.store, this.currentPage)
    GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  getValue(): string | number {
    this.params.value = this.value;
    const type = this.params.colDef.cellDataType;
    GridUtils.updateIsEditedAndForceRefresh(this.params);

    let newValue: string | number;
    if (type === 'text') {
      newValue = this.value.toString();
    } else if (type === 'number') {
      newValue = +NumberUtils.formatNumberToDecimals(+this.value, 2);
      if (this.colDefField === 'sellPriIncl1') {
        this.changeNgpOnPriceChange(newValue);
      }
    }
    GridUtils.updateSingleItem(this.params.data as NGPReport | StockItem , this.colDefField, this.value, this.store, this.currentPage)
    return newValue;
  }

  sanitizeInput(value: string): string {
    const sanitizedValue = value.replace(/[^0-9.]/g, '');

    // Allow only the first occurrence of a decimal point
    const parts = sanitizedValue.split('.');
    return parts.length > 2 ? `${parts[0]}.${parts[1]}` : sanitizedValue;
  }

  changeNgpOnPriceChange(newValue: number): void {
    if (!this.currentVisibleFields.nominalGP) {
      this.store.dispatch(updateSingleVisibleField({colId: 'nominalGP', value: true}));
    }
    this.params.data.nominalGP = newValue;

  }

  onKeyUp(event: KeyboardEvent): void {
    let sanitizedValue: string | number;
    const sanitizeFields = ['recommendedGP', 'ordLvl', 'packSize', 'sellPriExcl1' ]
    if ( sanitizeFields.includes(this.colDefField)){
      sanitizedValue = this.sanitizeInput(this.value as string);
    } else {
      sanitizedValue = this.value;
    }

      this.params.value = sanitizedValue;
      this.params.data[this.colDefField] = sanitizedValue;
      this.value = sanitizedValue;
      GridUtils.updateIsEditedAndForceRefresh(this.params);
  }

  afterGuiAttached(): void {
      this.input.nativeElement.focus();
  }

}
