import {createAction, props} from '@ngrx/store';
import {IStore} from '../../../shared/shared-models/store/store';
import {ISupplier} from '../../../shared/shared-models/stock/suppliers';
import {IError} from '../../../shared-utilities/models-old/error/error';

// ====================================================================================================
// Get Store
// ====================================================================================================

export const getStoreSuppliersByUserSelectedStore = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By User Selected Store'
);

export const getStoreSuppliersByStore = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store',
  props<{ store: IStore }>()
);

export const getStoreSuppliersByStoreSuccess = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store Success',
  props<{ suppliers: { [supplierId: string]: ISupplier }, store: IStore }>()
);

export const getStoreSuppliersByStoreFailure = createAction(
  '[Core Shared][Suppliers] Get Store Suppliers By Store Failure',
  props<{ error: IError }>()
);
