import {Injectable} from '@angular/core';
import {
  AngularFirestore,
  CollectionReference,
  QueryDocumentSnapshot,
  QuerySnapshot
} from '@angular/fire/compat/firestore';
import {IStore} from '../../shared-models/store/store';
import {
  path_shared_store_data_singular_document_advanced_filter_group_DELETE,
  path_shared_store_data_singular_document_advanced_filter_group_GET,
  path_shared_store_data_singular_document_advanced_filter_groups_GET,
  path_shared_store_data_singular_document_advanced_filter_POST
} from '../database-paths';
import {IAdvancedFilterGroup} from '../../shared-models/filter-groups/filter-groups';

@Injectable({
  providedIn: 'root',
})
export class CollectionSharedSingularDocumentsService {

  constructor(
    private angularFirestore: AngularFirestore,
  ) {
  }

  // ====================================================================================================
  // Data Timestamps
  // ====================================================================================================

  // ====================================================================================================
  // Advanced Filter Group/s
  // ====================================================================================================

  deleteStoreDataSingularDocumentAdvancedFilterGroup(store: IStore, filterId: number): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.angularFirestore.doc<IAdvancedFilterGroup>(
        path_shared_store_data_singular_document_advanced_filter_group_DELETE(store.storeId, `${filterId}`)
      )
        .delete()
        .then(() => { resolve(); })
        .catch((error): void => {
          error.message = `Error deleting Shared Document: Shared/Store/Advanced Filter.\n${error.message}`;
          reject(error);
        });
    });
  }

  getStoreDataSingularDocumentAdvancedFilterGroup(store: IStore, filterId: number): Promise<IAdvancedFilterGroup | null> {
    return new Promise<IAdvancedFilterGroup | null>((resolve, reject): void => {
      this.angularFirestore.doc<IAdvancedFilterGroup>(
        path_shared_store_data_singular_document_advanced_filter_group_GET(store.storeId, `${filterId}`),
      )
        .get()
        .toPromise()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            resolve(data);
          } else {
            resolve(null);
          }
        })
        .catch((error): void => {
          error.message = `Error getting Shared Document: Shared/Store/Advanced Filter.\n${error.message}`;
          reject(error);
        });
    });
  }

  getStoreDataSingularDocumentAdvancedFilterGroups(store: IStore): Promise<IAdvancedFilterGroup[]> {
    return new Promise<IAdvancedFilterGroup[]>((resolve, reject): void => {
      this.angularFirestore.collection<IAdvancedFilterGroup>(
        path_shared_store_data_singular_document_advanced_filter_groups_GET(store.storeId),
        (ref: CollectionReference<IAdvancedFilterGroup[]>) => ref.orderBy('id', 'desc')
      )
        .get()
        .toPromise()
        .then((querySnapshot: QuerySnapshot<IAdvancedFilterGroup>): void => {
          const advancedFilters: IAdvancedFilterGroup[] = [];
          querySnapshot.forEach((doc: QueryDocumentSnapshot<IAdvancedFilterGroup>): void => {
            const data = doc.data();
            advancedFilters.push(data);
          });
          resolve(advancedFilters);
        })
        .catch((error): void => {
          error.message = `Error getting Shared Document: Shared/Store/Advanced Filters.\n${error.message}`;
          reject(error);
        });
    });
  }

  async updateStoreDataSingularDocumentAdvancedFilter(
    store: IStore, advancedFilterGroup: IAdvancedFilterGroup
  ): Promise<void> {
    try {
      await this.angularFirestore
        .doc(path_shared_store_data_singular_document_advanced_filter_POST(store.storeId, advancedFilterGroup.id.toString()))
        .set(advancedFilterGroup, {merge: true});
    } catch (error) {
      error.message = `Error updating Shared Document: Shared/Store/Advanced Filters.\n${error.message}`;
      throw error;
    }
  }

}
