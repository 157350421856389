import {Component, Input} from '@angular/core';
import {Icons} from '../../../../shared-modules/shared-icons/icons';
import {ISharedModalBasic, ISharedModalBasicResponse} from '../../../shared-models/modals/shared-modal-basic';
import {ModalController, PopoverController} from '@ionic/angular';

@Component({
  selector: 'app-shared-modal-basic',
  templateUrl: './shared-modal-basic.component.html',
  styleUrls: ['./shared-modal-basic.component.scss'],
})
export class SharedModalBasicComponent implements ISharedModalBasic {

  icons: typeof Icons = Icons;

  @Input() buttonAccept: boolean;
  @Input() buttonAcceptText: string;
  @Input() buttonClose: boolean;
  @Input() buttonCloseCross: boolean;
  @Input() buttonCloseText: string;
  @Input() modalTitle: string;
  @Input() modalTitleIcon: Icons;
  @Input() modalTitleIconColor: 'green' | 'red' | 'yellow' | 'white' = 'white';
  @Input() contextHeading: string;
  @Input() contextDescription: string[];
  @Input() isImageVisible: boolean;
  @Input() imageUrl: string;

  constructor(
    private modalController: ModalController
  ) {
  }

  @Input() set popoverReference (popoverController: PopoverController) {
    void popoverController.dismiss().then();
  };

  onButtonPress(buttonPressed: 'button-accept' | 'button-close'): void {
    void this.modalController.dismiss(
      {buttonPressed} as ISharedModalBasicResponse,
      buttonPressed === 'button-accept' ? 'modal-accept' : 'modal-close'
    ).then((returned: boolean) => returned);
  }

  trackByFunction(index: number): number {
    return index;
  }

}
